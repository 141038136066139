import { Button, IconButton } from "@mui/material";
import "./RecipesHeading.scss";
import { useNavigate } from "react-router-dom";

import { AddNew } from "../../assets/Icons/AddNew";
import { ArrowBack } from "../../assets/Icons/ArrowBack";
import { PageHeading } from "../PageHeading/PageHeading";

export interface RecipesHeadingProps {
  onCreateRecipeClick?: () => void;
  onCancelSelectClick?: () => void;
  isCreateDisabled?: boolean;
  isSelect?: boolean;
}

export const RecipesHeading = (props: RecipesHeadingProps) => {
  const navigate = useNavigate();

  return (
    <PageHeading
      leftAdornment={
        !props.isSelect ? (
          <IconButton
            className="recipes-heading-icon-button"
            onClick={() => navigate(-1)}
          >
            <ArrowBack />
          </IconButton>
        ) : (
          <Button
            className="recipes-heading-cancel-button"
            onClick={props.onCancelSelectClick}
          >
            Отмена
          </Button>
        )
      }
      rightAdornment={
        <IconButton
          className="recipes-heading-icon-button"
          onClick={props.onCreateRecipeClick}
          disabled={props.isCreateDisabled}
        >
          <AddNew />
        </IconButton>
      }
    >
      Рецепты
    </PageHeading>
  );
};
