export enum URL_PARAMS {
  SHARE_LIST_KEY = "shareListKey",
}

export enum LOCAL_STORAGE_KEYS {
  ACCESS_TOKEN = "access_token",
  REFRESH_TOKEN = "refresh_token",
  USER_ID = "user_id",
  USER_EMAIL = "user_email",
  USER_NAME = "user_name",
  USER_LOGO_URL = "user_logo_url",
  IS_UPDATE_FOUND = "is_update_found",
  FIRST_VISIT_DATE = "first_visit_date",
}

export enum COMMON_STORE_KEYS {
  LISTS_LAST_SYNC = "listsLastSync",
  LISTS_LAST_CHANGE = "listsLastChange",
  PROMPTS_LAST_SYNC = "promptsLastSync",
  PROMPTS_LAST_CHANGE = "promptsLastChange",
  CATEGORIES_LAST_SYNC = "categoriesLastSync",
  CATEGORIES_LAST_CHANGE = "categoriesLastChange",
  SELECTED_LIST_LOCAL_ID = "selectedListLocalId",
  INSTALL_DIALOG_LAST_SHOW = "installDialogLastShow",
  INSTALL_DIALOG_TOGGLE = "installDialogToggle",
  SHOW_REGISTRATION_LAST_SHOW = "showRegistationLastShow",
}

export enum Paths {
  LOGIN = "/login",
  LOGIN_HOME = "/login-home",
  SIGN_UP = "/signup",
  SUPPORT = "/support",
  FUTURE_FEATURES = "/future-features",
  SETTINGS = "/settings",
  RECIPES = "/recipes",
  ADMIN = "/admin",
}

export const emailRegex = /(.+)@(.+){2,}\.(.+){2,}/;

export const CATEGORIES_COLORS: string[] = [
  "#0EA472",
  "#CBBEB5",
  "#423F3B",
  "#525266",
  "#FF6666",
  "#C0392B",
  "#51ABD8",
  "#606060",
  "#6D7782",
  "#900651",
  "#40BD94",
  "#21678D",
  "#1C8986",
  "#272932",
  "#864F9E",
  "#F24848",
  "#4C4C4C",
  "#D9792B",
  "#F2DA33",
  "#69818f",
  "#57B71B",
  "#6039B0",
  "#66dda2",
  "#1be4f6",
  "#f43ad4",
  "#E75FD7",
  "#7085D0",
  "#0F747F",
  "#251662",
  "#20B558",
  "#a6e450",
  "#C8FD11",
  "#987896",
  "#A57DF6",
  "#53E0CE",
  "#36B19A",
  "#894D09",
  "#5C689F",
  "#6283FD",
  "#921825",
];

export const USERS_ACTIVITIES_LAST_DAYS = 7;

export const START_DAY_FORMAT = "YYYY-MM-DDT00:00:00";

export const END_DAY_FORMAT = "YYYY-MM-DDT23:59:59";

export const LIST_EXAMPLE_NAME = "Продукты (Пример)";

export const LIST_ITEMS_EXAMPLE_NAMES = ["мясо", "колбаса", "молоко", "сыр", "хлеб"];
