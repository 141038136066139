import { Box, Typography } from "@mui/material";
import "./PageHeading.scss";
import { ReactNode } from "react";

export interface PageHeadingProps {
  leftAdornment?: ReactNode;
  rightAdornment?: ReactNode;
  children?: ReactNode;
}

export const PageHeading = (props: PageHeadingProps) => {
  return (
    <Box className="page-heading">
      <Box className="page-heading-left">
        {props.leftAdornment && props.leftAdornment}
      </Box>
      <Typography className="page-heading-title">{props.children}</Typography>
      <Box className="page-heading-right">
        {props.rightAdornment && props.rightAdornment}
      </Box>
    </Box>
  );
};
