import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";
import { Button, Box } from "@mui/material";
import "./ListItemCreationToolbar.scss";

import { VoiceAnimation } from "../VoiceAnimation/VoiceAnimation";

export interface ListItemCreationToolbarProps {
  listening: boolean;
  onAddTextListItemClick: () => void;
  onAddVoiceListItemClick: () => void;
  onCancelVoiceListeningClick: () => void;
  browserSupportsSpeechRecognition?: boolean;
}

export const ListItemCreationToolbar = (props: ListItemCreationToolbarProps) => {
  return (
    <Box className="li-creation-toolbar" bottom={"0"}>
      {props.browserSupportsSpeechRecognition && (
        <>
          {props.listening ? (
            <Button
              className="li-creation-toolbar-button"
              onClick={() => props.onCancelVoiceListeningClick()}
            >
              <VoiceAnimation className="li-creation-toolbar-button-icon" />
            </Button>
          ) : (
            <Button
              className="li-creation-toolbar-button"
              onClick={() => props.onAddVoiceListItemClick()}
            >
              <MicNoneOutlinedIcon className="li-creation-toolbar-button-icon" />
              Голосом
            </Button>
          )}
        </>
      )}
      <Button
        className="li-creation-toolbar-button"
        onClick={() => {
          props.onAddTextListItemClick();
        }}
      >
        <EditNoteOutlinedIcon
          className="li-creation-toolbar-button-icon"
          fontSize="large"
        />
        Ввод
      </Button>
    </Box>
  );
};
