import { Box, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import "./CustomSelectItem.scss";

export interface CustomSelectItemProps {
  name?: ReactNode;
  children?: ReactNode;
  className?: string;
}

export const CustomSelectItem = (props: CustomSelectItemProps) => {
  return (
    <Box className={`custom-select-item ${props.className ? props.className : ""}`}>
      <Typography className="custom-select-item-label">{props.name}</Typography>
      <Box>{props.children}</Box>
    </Box>
  );
};
