import "./RecipesSearch.scss";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { debounce, InputAdornment } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

import { BaseInput } from "../BaseInput/BaseInput";

export interface RecipesSearchProps {
  onSearch: (query: string) => void;
  disabled: boolean;
}

export const RecipesSearch = (props: RecipesSearchProps) => {
  const [value, setValue] = useState<string | null>(null);

  const debounceSearch = useMemo(() => {
    return debounce((query: string) => {
      props.onSearch(query);
    }, 500);
  }, []);

  useEffect(() => {
    if (value !== null) {
      debounceSearch(value);
    }
  }, [value]);

  return (
    <BaseInput
      className="recipes-search"
      placeholder="Поиск по рецептам"
      value={value ?? ""}
      onChange={(e) => setValue(e.target.value.slice(0, 200))}
      disabled={props.disabled}
      startAdornment={
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      }
      endAdornment={
        value && (
          <InputAdornment position="end" onClick={() => setValue("")}>
            <HighlightOffOutlinedIcon />
          </InputAdornment>
        )
      }
    />
  );
};
