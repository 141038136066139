import "./TextArea.scss";
import { Box } from "@mui/material";
import React from "react";

import { InputError } from "../InputError/InputError";

export interface InputLabelProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  className?: string;
  placeholder?: string;
  autoFocus?: boolean;
  errorMessage?: string;
}

export const TextArea = (props: InputLabelProps) => {
  return (
    <Box className={`textarea ${props.className ? props.className : ""} `}>
      <textarea
        value={props.value}
        onChange={props.onChange}
        className={`textarea-input ${props.errorMessage && "textarea-input_error"}`}
        placeholder={props.placeholder ?? ""}
        autoFocus={props.autoFocus ?? false}
      />
      {props.errorMessage && (
        <InputError className="textarea-error" type="danger">
          {props.errorMessage}
        </InputError>
      )}
    </Box>
  );
};
