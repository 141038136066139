import { configureStore } from "@reduxjs/toolkit";

import { adminSlice } from "./admin/adminSlice";
import { applicationSlice } from "./application/applicationSlice";
import { authSlice } from "./auth/authSlice";
import { categoriesSlice } from "./categories/categoriesSlice";
import { listsSlice } from "./lists/listsSlice";
import { loginSlice } from "./login/loginSlice";
import { promptsSlice } from "./prompts/promptsSlice";
import { recipesSlice } from "./recipes/recipesSlice";

export const store = configureStore({
  reducer: {
    [authSlice.name]: authSlice.reducer,
    [listsSlice.name]: listsSlice.reducer,
    [categoriesSlice.name]: categoriesSlice.reducer,
    [promptsSlice.name]: promptsSlice.reducer,
    [applicationSlice.name]: applicationSlice.reducer,
    [loginSlice.name]: loginSlice.reducer,
    [recipesSlice.name]: recipesSlice.reducer,
    [adminSlice.name]: adminSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
