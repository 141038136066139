import { Box, Button, Typography } from "@mui/material";
import { useState, TouchEvent } from "react";

import { Check } from "../../assets/Icons/Check";
import { QuantityInput } from "../QuantityInput/QuantityInput";
import "./RecipeItem.scss";

interface RecipeItemProps {
  id: number;
  name: string;
  quantity: number;
  onQuantityChange?: (id: number, quantity: number) => void;
  onDelete?: (id: number) => void;
  disabled?: boolean;
}

export const RecipeItem = (props: RecipeItemProps) => {
  const [isClicked, setIsClicked] = useState(false);
  const [startX, setStartX] = useState<number | null>(null);

  const handleTouchStart = (e: TouchEvent) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e: TouchEvent) => {
    if (startX !== null) {
      const deltaX = e.touches[0].clientX - startX;

      if (deltaX < -50) {
        setIsClicked(true);
      } else if (deltaX > 50) {
        setIsClicked(false);
      }
    }
  };

  const handleTouchEnd = () => {
    setStartX(null);
  };

  const handleQuantityChange = (value: string) => {
    if (props.onQuantityChange) {
      props.onQuantityChange(props.id, +value);
    }
  };

  const handleDeleteClick = () => {
    setIsClicked(false);
    if (props.onDelete) {
      props.onDelete(props.id);
    }
  };

  return (
    <Box
      className="recipe-item"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <Box
        className={`recipe-item-name ${props.disabled ? "recipe-item-name_disabled" : ""}`}
        onClick={() => setIsClicked(!isClicked)}
      >
        <Check />
        <Typography>{props.name}</Typography>
      </Box>
      <Box className="recipe-item-controls">
        <QuantityInput
          disabled={props.disabled}
          className="recipe-item-controls-quantity"
          value={props.quantity.toString()}
          onChange={handleQuantityChange}
        />
        {props.onDelete && (
          <Button
            className={`recipe-item-controls-delete ${isClicked ? "recipe-item-controls-delete_visible" : ""}`}
            onClick={handleDeleteClick}
          >
            Удалить
          </Button>
        )}
      </Box>
    </Box>
  );
};
