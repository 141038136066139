import "./RecipesList.scss";
import { Refresh } from "@mui/icons-material";
import { Avatar, CircularProgress, List } from "@mui/material";

import { Plus } from "../../assets/Icons/Plus";
import { Recipe } from "../../assets/Icons/Recipe";
import { SignalLost } from "../../assets/Icons/SignalLost";
import { BaseButton } from "../BaseButton/BaseButton";
import { EmptyContent } from "../EmptyContent/EmptyContent";
import { RecipeCard, RecipeCardData } from "../RecipeCard/RecipeCard";

interface RecipesListProps {
  recipes: RecipeCardData[];
  areRecipesLoading: boolean;
  isOnline: boolean;
  signedIn: boolean;
  isSelection: boolean;
  selectedRecipes: number[];
  onRecipeClick: (recipeId: number) => void;
  onCreateRecipeClick: () => void;
  onDeleteRecipeClick: (recipeId: number) => void;
  onToggleRecipeClick: (recipeId: number) => void;
  onEditRecipeClick: (recipeId: number) => void;
  onLoginClick: () => void;
}

export const RecipesList = (props: RecipesListProps) => {
  if (!props.isOnline) {
    return (
      <EmptyContent
        image={<SignalLost />}
        title="Нет доступа к интернету"
        subtitle="Рецепты будут доступны только при наличии интернета"
        button={
          <BaseButton onClick={() => window.location.reload()}>
            <Refresh /> Обновить
          </BaseButton>
        }
      />
    );
  }

  if (!props.signedIn) {
    return (
      <EmptyContent
        image={<Avatar />}
        title="Вы не авторизованны"
        subtitle="Рецепты доступны только авторизованным пользователям"
        button={<BaseButton onClick={props.onLoginClick}>Войти</BaseButton>}
      />
    );
  }

  if (props.areRecipesLoading) {
    return <EmptyContent image={<CircularProgress />} />;
  }

  if (!props.recipes?.length) {
    return (
      <EmptyContent
        image={<Recipe />}
        title="Нет рецептов"
        subtitle="Создайте свой рецепт нажав на «+» в правом верхнем углу экрана"
        button={
          <BaseButton onClick={props.onCreateRecipeClick}>
            <Plus color={"#fff"} /> Добавить рецепт
          </BaseButton>
        }
      />
    );
  }

  return (
    <List className="recipes-list">
      {props.recipes.map((recipe) => (
        <RecipeCard
          key={recipe.id}
          recipeData={recipe}
          isSelectable={props.isSelection}
          isSelected={props.selectedRecipes?.includes(recipe.id)}
          onClick={props.onRecipeClick}
          onDeleteClick={props.onDeleteRecipeClick}
          onToggleClick={props.onToggleRecipeClick}
          onEditClick={props.onEditRecipeClick}
        />
      ))}
    </List>
  );
};
