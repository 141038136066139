import { Autocomplete, Box, ListItem, TextField, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { SyntheticEvent, useEffect, useState } from "react";

import {
  END_DAY_FORMAT,
  START_DAY_FORMAT,
  USERS_ACTIVITIES_LAST_DAYS,
} from "../../utils/constants";
import { getLastDaysPeriod } from "../../utils/dateTimeUtil";
import "./AdminActivityChart.scss";

export type UserFilterOption = {
  name: string;
  useCount: number;
};

export interface AdminActivityChartProps {
  users: UserFilterOption[];
  usersActivities: Record<string, { userName: string }[]>;
  onPeriodChange: (startDate: string, endDate: string) => void;
}

const defaultPeriod = getLastDaysPeriod(USERS_ACTIVITIES_LAST_DAYS);

export const AdminActivityChart = (props: AdminActivityChartProps) => {
  const [user, setUser] = useState<UserFilterOption | null>(null);
  const [startDate, setStartDate] = useState<Dayjs>(dayjs(defaultPeriod.startDate));
  const [endDate, setEndDate] = useState<Dayjs>(dayjs(defaultPeriod.endDate));
  const [chartDays, setChartDays] = useState<string[]>([]);
  const [chartCounts, setChartCounts] = useState<number[]>([]);

  useEffect(() => {
    const daysDiff = endDate.diff(startDate, "day");
    const daysArray = Array.from({ length: daysDiff + 1 }, (_, i) =>
      startDate.clone().add(i, "day").format(START_DAY_FORMAT),
    );
    setChartDays(daysArray.map((day) => dayjs(day).format("DD.MM.YYYY")));
    if (user) {
      setChartCounts(
        daysArray.map(
          (day) =>
            props.usersActivities[day]?.filter(
              (activity) => activity.userName === user.name,
            )?.length ?? 0,
        ),
      );
    } else {
      setChartCounts(daysArray.map((day) => props.usersActivities[day]?.length || 0));
    }
  }, [props.usersActivities, user]);

  const handleStartDateChange = (startDate: Dayjs | null) => {
    if (startDate) {
      setStartDate(startDate);
      props.onPeriodChange(
        startDate.format(START_DAY_FORMAT),
        endDate.format(END_DAY_FORMAT),
      );
    }
  };

  const handleEndDateChange = (endDate: Dayjs | null) => {
    if (endDate) {
      setEndDate(endDate);
      props.onPeriodChange(
        startDate.format(START_DAY_FORMAT),
        endDate.format(END_DAY_FORMAT),
      );
    }
  };

  const handleUserAutoCompleteChange = (
    _: SyntheticEvent,
    newValue: UserFilterOption | null,
  ) => {
    setUser(newValue);
  };

  return (
    <Box className="admin-activity-chart">
      <Box className="admin-activity-chart-filters">
        <Autocomplete
          className="admin-activity-chart-filters-autocomplete"
          renderInput={(params) => <TextField {...params} placeholder="Пользователь" />}
          options={props.users}
          renderOption={(props, option) => (
            <ListItem key={option.name} {...props}>
              <Typography>
                {option.name} - <span style={{ color: "grey" }}>{option.useCount}</span>
              </Typography>
            </ListItem>
          )}
          getOptionLabel={(option) => option.name}
          onChange={handleUserAutoCompleteChange}
        />
        <DatePicker
          className="admin-activity-chart-filters-date"
          renderInput={(params) => <TextField {...params} placeholder="Дата начала" />}
          label="Дата начала"
          value={startDate}
          onChange={handleStartDateChange}
        />
        <DatePicker
          className="admin-activity-chart-filters-date"
          renderInput={(params) => <TextField {...params} placeholder="Дата конца" />}
          label="Дата конца"
          value={endDate}
          onChange={handleEndDateChange}
        />
      </Box>
      <Box className="admin-activity-chart-body">
        {chartDays.length > 0 && (
          <BarChart
            xAxis={[
              {
                id: "days",
                data: chartDays,
                scaleType: "band",
              },
            ]}
            yAxis={[
              {
                id: "counts",
                tickMinStep: 1,
              },
            ]}
            series={[
              {
                data: chartCounts,
              },
            ]}
          />
        )}
      </Box>
    </Box>
  );
};
