import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../store";

export const selectRecipesState = (state: RootState) => state.recipes;

export const selectSortedRecipes = createSelector(
  (state: RootState) => state.recipes.recipes,
  (recipes) => [...recipes].sort((a, b) => b.id - a.id),
);
