import { IconButton } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ArrowBack } from "../../../assets/Icons/ArrowBack";
import { AdminActivityChart } from "../../../components/AdminActivityChart/AdminActivityChart";
import { PageHeading } from "../../../components/PageHeading/PageHeading";
import { PageLoading } from "../../../components/PageLoading/PageLoading";
import { fetchUsersActivities } from "../../../store/admin/adminSlice";
import { selectUsers, selectUsersActivities } from "../../../store/admin/selectors";
import { selectAuthState } from "../../../store/auth/selectors";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { USERS_ACTIVITIES_LAST_DAYS } from "../../../utils/constants";
import { getLastDaysPeriod } from "../../../utils/dateTimeUtil";
import "./AdminPage.scss";

export const AdminPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user, signedIn } = useAppSelector(selectAuthState);
  const usersActivities = useAppSelector(selectUsersActivities);
  const users = useAppSelector(selectUsers);

  useEffect(() => {
    const period = getLastDaysPeriod(USERS_ACTIVITIES_LAST_DAYS);
    dispatch(fetchUsersActivities(period));
  }, []);

  useEffect(() => {
    if (!signedIn || user.isAdmin === false) {
      navigate("/");
    }
  }, [user, signedIn]);

  const handlePeriodChange = (startDate: string, endDate: string) => {
    dispatch(fetchUsersActivities({ startDate, endDate }));
  };

  if (!signedIn || !user.isAdmin) {
    return <PageLoading />;
  }

  return (
    <Stack className="admin-page">
      <PageHeading
        leftAdornment={
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBack />
          </IconButton>
        }
      >
        Статистика использования
      </PageHeading>
      <Stack className="admin-page-body">
        <AdminActivityChart
          users={users}
          usersActivities={usersActivities}
          onPeriodChange={handlePeriodChange}
        />
      </Stack>
    </Stack>
  );
};
