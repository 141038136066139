import "./CategoriesList.scss";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { IconButton, List, ListItem, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";

import { ListItemCategoryInternalModel } from "../../services/internalStorage/models/ListItemCategoryInternalModel";
import { CategoryBadge } from "../CategoryBadge/CategoryBadge";

export interface CategoriesListProps {
  categories: ListItemCategoryInternalModel[];
  selectedCategory: ListItemCategoryInternalModel | null;
  onCategoryClick: (category: ListItemCategoryInternalModel) => void;
  onEditCategoryClick: (category: ListItemCategoryInternalModel) => void;
}

export const CategoriesList = (props: CategoriesListProps) => {
  const selectedCategoryRef = useRef<HTMLLIElement | null>(null);

  const handleEditCategoryClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    category: ListItemCategoryInternalModel,
  ) => {
    e.stopPropagation();
    props.onEditCategoryClick(category);
  };

  useEffect(() => {
    if (selectedCategoryRef.current) {
      selectedCategoryRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [props.selectedCategory?.localId]);

  return (
    <List className="categories-list">
      {props.categories.map((category) => (
        <ListItem
          ref={
            props.selectedCategory?.localId === category.localId
              ? selectedCategoryRef
              : null
          }
          className={`categories-list-item ${
            props.selectedCategory?.localId === category.localId &&
            "categories-list-item--active"
          }`}
          key={category.localId}
          onClick={() => props.onCategoryClick(category)}
        >
          <CategoryBadge
            color={category.colorDark}
            className="categories-list-item-badge"
          />
          <Typography className="categories-list-item-name">{category.name}</Typography>
          <IconButton
            className="categories-list-item-edit"
            onClick={(e) => handleEditCategoryClick(e, category)}
          >
            <ModeEditOutlinedIcon color="info" />
          </IconButton>
        </ListItem>
      ))}
    </List>
  );
};
