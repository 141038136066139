import "./CurtainPopupHeading.scss";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { ReactNode } from "react";

export interface CurtainPopupHeadingProps {
  title?: string;
  subtitle?: string;
  onBack?: () => void;
  onCancel?: () => void;
  onCancelText?: ReactNode;
  onConfirm?: () => void;
  onConfirmText?: ReactNode;
  isConfirmDisabled?: boolean;
}

export const CurtainPopupHeading = (props: CurtainPopupHeadingProps) => {
  return (
    <Box className="curtain-popup-heading">
      <Box>
        {props.onBack && (
          <IconButton
            className="curtain-popup-heading-back"
            onClick={props.onBack}
            disableRipple
          >
            <ArrowBackIosNewRoundedIcon />
          </IconButton>
        )}
        {props.onCancel && (
          <Button
            className="curtain-popup-heading-button curtain-popup-heading-button-left"
            color="primary"
            onClick={props.onCancel}
            disableRipple
          >
            {props.onCancelText ?? "Отменить"}
          </Button>
        )}
      </Box>
      <Box>
        <Typography className="curtain-popup-heading-title">{props.title}</Typography>
        <Typography className="curtain-popup-heading-subtitle">
          {props.subtitle}
        </Typography>
      </Box>
      <Box>
        {props.onConfirm && (
          <Button
            className="curtain-popup-heading-button curtain-popup-heading-button-right"
            color="primary"
            onClick={props.onConfirm}
            disabled={props.isConfirmDisabled}
            disableRipple
          >
            {props.onConfirmText ?? "Готово"}
          </Button>
        )}
      </Box>
    </Box>
  );
};
