export const Transfer = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M5.9875 3V5.9875H4.5125V3C4.5125 2.59084 4.65804 2.24089 4.94946 1.94946C5.24089 1.65804 5.59084 1.5125 6 1.5125H15C15.4092 1.5125 15.7591 1.65804 16.0505 1.94946C16.342 2.24089 16.4875 2.59084 16.4875 3V12C16.4875 12.4092 16.342 12.7591 16.0505 13.0505C15.7591 13.342 15.4092 13.4875 15 13.4875H6C5.59084 13.4875 5.24089 13.342 4.94946 13.0505C4.65804 12.7591 4.5125 12.4092 4.5125 12V10.5125H5.9875V12V12.0125H6H15H15.0125V12V3V2.9875H15H6H5.9875V3ZM3 15.0125H13.4875V16.4875H3C2.59084 16.4875 2.24089 16.342 1.94946 16.0505C1.65804 15.7591 1.5125 15.4092 1.5125 15V4.5125H2.9875V15V15.0125H3ZM9.88125 8.9875H4.5125V7.5125H9.88125H9.9111L9.89016 7.49123L8.71761 6.30007L9.75 5.26768L12.7323 8.25L9.75 11.2323L8.71761 10.1999L9.89016 9.00877L9.9111 8.9875H9.88125Z"
        fill="#08875D"
        stroke="#08875D"
        strokeWidth="0.025"
      />
    </svg>
  );
};
