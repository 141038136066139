export const Download = ({ color }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M2.8509 6.16651L4.0002 4.97616L6.15787 7.13383L6.17921 7.15517V7.12499V0.345828H7.82087V7.12499V7.15517L7.84221 7.13383L9.99988 4.97616L11.1492 6.16651L7.00004 10.3156L2.8509 6.16651ZM12.0125 12V9.51249H13.6542V12C13.6542 12.455 13.4923 12.8442 13.1683 13.1682C12.8442 13.4923 12.455 13.6542 12 13.6542H2.00004C1.54505 13.6542 1.15586 13.4923 0.831796 13.1682C0.507733 12.8442 0.345874 12.455 0.345874 12V9.51249H1.98754V12V12.0125H2.00004H12H12.0125V12Z"
        fill={color || "#08875D"}
        stroke="#F8FAFC"
        strokeWidth="0.025"
      />
    </svg>
  );
};
