export const Plus = ({ color }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M8.25 9.75H4.5C4.2875 9.75 4.10938 9.67813 3.96563 9.53438C3.82188 9.39062 3.75 9.2125 3.75 9C3.75 8.7875 3.82188 8.60938 3.96563 8.46563C4.10938 8.32188 4.2875 8.25 4.5 8.25H8.25V4.5C8.25 4.2875 8.32188 4.10938 8.46563 3.96563C8.60938 3.82188 8.7875 3.75 9 3.75C9.2125 3.75 9.39062 3.82188 9.53438 3.96563C9.67813 4.10938 9.75 4.2875 9.75 4.5V8.25H13.5C13.7125 8.25 13.8906 8.32188 14.0344 8.46563C14.1781 8.60938 14.25 8.7875 14.25 9C14.25 9.2125 14.1781 9.39062 14.0344 9.53438C13.8906 9.67813 13.7125 9.75 13.5 9.75H9.75V13.5C9.75 13.7125 9.67813 13.8906 9.53438 14.0344C9.39062 14.1781 9.2125 14.25 9 14.25C8.7875 14.25 8.60938 14.1781 8.46563 14.0344C8.32188 13.8906 8.25 13.7125 8.25 13.5V9.75Z"
        fill={color || "#1B2227"}
      />
    </svg>
  );
};
