import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import bucketImage from "../../../assets/img/bucket.svg";
import "./InstallPwaDialog.scss";

interface InstallPwaDialogProps {
  open: boolean;
  onInstall: (() => Promise<boolean> | null) | null;
  onClose: () => void;
}

export const InstallPwaDialog = (props: InstallPwaDialogProps) => {
  const handleInstallClick = async () => {
    if (props.onInstall) {
      props.onInstall();
    }
    props.onClose();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle className="install-pwa-title">
        Нравится приложение? Сохраните.
      </DialogTitle>
      <DialogContent className="install-pwa-info">
        <Box
          component="img"
          className="install-pwa-info-logo"
          src={bucketImage}
          alt="лого"
        />
        <Typography className="install-pwa-info-title">Listy - список покупок</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Отмена</Button>
        <Button onClick={handleInstallClick}>Сохранить</Button>
      </DialogActions>
    </Dialog>
  );
};
