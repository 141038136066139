import "./RecipeForm.scss";
import { Box } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { ChangeEvent, useEffect, useState } from "react";

import { Clock } from "../../assets/Icons/Clock";
import { Persons } from "../../assets/Icons/Persons";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { uploadRecipeImage } from "../../store/recipes/recipesSlice";
import { RootState } from "../../store/store";
import { BaseInput } from "../BaseInput/BaseInput";
import { CurtainPopupHeading } from "../CurtainPopupHeading/CurtainPopupHeading";
import { ImageUploader } from "../ImageUploader/ImageUploader";
import { InputError } from "../InputError/InputError";
import { InputLabel } from "../InputLabel/InputLabel";
import { QuantityInput } from "../QuantityInput/QuantityInput";
import { RecipeDetailedData } from "../RecipeDetailed/RecipeDetailed";
import { RecipeItems } from "../RecipeItems/RecipeItems";
import { TextArea } from "../TextArea/TextArea";
import { TimeSelect } from "../TimeSelect/TimeSelect";

export type RecipeFormData = {
  name: string;
  photoUrl: string;
  persons: number;
  cookingTime: string;
  description: string;
  created: string;
  recipeItems: { id: number; name: string; quantity: number }[];
};

export interface RecipeFormProps {
  id: string;
  formTitle: string;
  onSubmit: (recipeData: RecipeFormData) => Promise<void>;
  onClose: () => void;
  formSubtitle?: string;
  initialData?: RecipeDetailedData | null;
  hidden?: boolean;
}

export const RecipeForm = (props: RecipeFormProps) => {
  const dispatch = useAppDispatch();
  const { prompts } = useAppSelector((state: RootState) => state.prompts);
  const [photoUrl, setPhotoUrl] = useState("");
  const [personsCount, setPersonsCount] = useState("3");
  const [cookingTime, setCookingTime] = useState<Dayjs | null>(dayjs().hour(1).minute(0));
  const [name, setName] = useState("");
  const [items, setItems] = useState<{ id: number; name: string; quantity: number }[]>(
    [],
  );
  const [description, setDescription] = useState("");
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(false);
  const [nameError, setNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [cookingTimeError, setCookingTimeError] = useState("");
  const [isImageUploading, setIsImageUploading] = useState(false);

  useEffect(() => {
    setPhotoUrl(props.initialData?.photoUrl ?? "");
    setPersonsCount((props.initialData?.persons ?? "3")?.toString());
    setCookingTime(
      props.initialData?.cookingTime
        ? dayjs(props.initialData.cookingTime, "HH:mm:ss")
        : dayjs().hour(1).minute(0),
    );
    setName(props.initialData?.name ?? "");
    setItems(props.initialData?.recipeItems ?? []);
    setDescription(props.initialData?.description ?? "");
  }, [props.initialData, props.hidden]);

  const resetForm = () => {
    setIsImageUploading(false);
    setPhotoUrl("");
    setPersonsCount("3");
    setCookingTime(dayjs().hour(1).minute(0));
    setName("");
    setItems([]);
    setDescription("");
    setNameError("");
    setDescriptionError("");
  };

  const handleImageChange = async (file: File) => {
    setIsImageUploading(true);
    const imageUrl = (await dispatch(uploadRecipeImage(file)))?.payload as string | null;
    setIsImageUploading(false);
    setPhotoUrl(imageUrl ?? "");
  };

  const handleCookingTimeChange = (value: Dayjs | null) => {
    setCookingTime(value);
    setCookingTimeError("");
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value.slice(0, 200));
    setNameError("");
  };

  const handleRecipeItemCreate = (name: string, quantity: number) => {
    setItems([...items, { id: 0, name, quantity }]);
  };

  const handleRecipeItemQuantityChange = (index: number, quantity: number) => {
    setItems([
      ...items.map((item, i) => {
        if (i === index) {
          return { ...item, quantity };
        }
        return item;
      }),
    ]);
  };

  const handleRecipeItemDelete = (index: number) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const handleDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value.slice(0, 1500));
    setDescriptionError("");
  };

  const handleClose = () => {
    props.onClose();
    resetForm();
  };

  const handleSubmit = async () => {
    if (name && description && cookingTime?.isValid()) {
      setIsConfirmDisabled(true);
      const submitData = {
        name,
        photoUrl,
        persons: +personsCount,
        cookingTime: cookingTime?.format("HH:mm") || "",
        description,
        created: new Date().toISOString(),
        recipeItems: items,
      };
      await props.onSubmit(submitData);
      handleClose();
      setIsConfirmDisabled(false);
    } else {
      if (!name) {
        setNameError("Поле название не может быть пустым");
      }
      if (!description) {
        setDescriptionError("Поле приготовление не может быть пустым");
      }
      if (!cookingTime?.isValid()) {
        setCookingTimeError("Время приготовления должно быть от 00:00 до 23:59");
      }
    }
  };

  return (
    <Box className={`recipe-form ${props.hidden ? "recipe-form_hidden" : ""}`}>
      <CurtainPopupHeading
        title={props.formTitle}
        subtitle={props.formSubtitle}
        onCancel={handleClose}
        onConfirm={handleSubmit}
        isConfirmDisabled={isConfirmDisabled}
      />
      <Box className="recipe-form-body">
        <ImageUploader
          id={props.id}
          onChange={handleImageChange}
          imageUrl={photoUrl}
          isUploading={isImageUploading}
        />
        <Box className="recipe-form-body-info">
          <Box className="recipe-form-body-info-section">
            <Persons />
            <QuantityInput
              className="recipe-form-body-info-section-persons"
              value={personsCount}
              onChange={setPersonsCount}
              maxSymbols={2}
            />
          </Box>
          <Box className="recipe-form-body-info-section">
            <Clock />
            <TimeSelect
              value={cookingTime}
              onChange={handleCookingTimeChange}
              label={"Время приготовления"}
            />
          </Box>
          {cookingTimeError && (
            <InputError type="danger" className="recipe-form-body-info-error">
              {cookingTimeError}
            </InputError>
          )}
        </Box>
        <Box className="recipe-form-body-name">
          <InputLabel>Название</InputLabel>
          <BaseInput
            value={name}
            onChange={handleNameChange}
            placeholder="Название рецепта"
            errorMessage={nameError}
          />
        </Box>
        <Box>
          <InputLabel>Ингредиенты</InputLabel>
          <RecipeItems
            items={items}
            onItemCreate={handleRecipeItemCreate}
            prompts={prompts}
            onDeleteItemClick={handleRecipeItemDelete}
            onItemQuantityChange={handleRecipeItemQuantityChange}
          />
        </Box>
        <Box>
          <InputLabel>Приготовление</InputLabel>
          <TextArea
            value={description}
            onChange={handleDescriptionChange}
            placeholder="Напишите описание рецепта"
            errorMessage={descriptionError}
          />
        </Box>
      </Box>
    </Box>
  );
};
