import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import "./AddToListDialog.scss";
import { useState } from "react";

import { ListInternalModel } from "../../../services/internalStorage/models/ListInternalModel";
import { ListsList } from "../../ListsList/ListsList";

export interface AddToListDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (listLocalId: number) => void;
  lists: ListInternalModel[];
  user?: { id?: string } | null;
}

export const AddToListDialog = (props: AddToListDialogProps) => {
  const [checkedListLocalId, setCheckedListLocalId] = useState<number | null>(null);

  const handleClose = () => {
    props.onClose();
    setCheckedListLocalId(null);
  };

  const handleConfirm = () => {
    if (checkedListLocalId) {
      props.onConfirm(checkedListLocalId);
      handleClose();
    }
  };

  return (
    <Dialog open={props.open} onClose={handleClose} className="add-to-list-dialog">
      <DialogTitle className="add-to-list-dialog-title">Добавить в список</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        className="add-to-list-dialog-close"
      >
        <Close />
      </IconButton>
      <DialogContent className="add-to-list-dialog-content">
        <Typography className="add-to-list-dialog-content-text">
          Выберите список, куда добавить ингредиенты
        </Typography>
        <ListsList
          lists={props.lists}
          onSelectList={(id) => setCheckedListLocalId(id)}
          selectedListId={checkedListLocalId}
          user={props.user}
        />
      </DialogContent>
      <DialogActions className="add-to-list-dialog-actions">
        <Button
          onClick={handleConfirm}
          variant="contained"
          className="add-to-list-dialog-actions-confirm"
          disabled={!checkedListLocalId}
        >
          Добавить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
