import { getDateValue, getUniqueId } from "../../../utils/dateTimeUtil";
import { ListInternalModel } from "../../internalStorage/models/ListInternalModel";
import { ListItemCategoryInternalModel } from "../../internalStorage/models/ListItemCategoryInternalModel";
import { ListItemInternalModel } from "../../internalStorage/models/ListItemInternalModel";
import { InternalStorageCategoriesService } from "../../internalStorage/services/InternalStorageCategoriesService";
import { InternalStorageListsService } from "../../internalStorage/services/InternalStorageListsService";
import {
  ListDetailedDto,
  ListDto,
  ListItemCategoryDto,
  ListItemDto,
  ListItemsService,
  ListsService,
} from "../../openapi";
import { Syncer } from "../core/Syncer";

export const convertServerListItemCategoryToLocal = (
  serverCategory?: ListItemCategoryDto,
): ListItemCategoryInternalModel | null =>
  serverCategory
    ? {
        id: serverCategory.id,
        localId: getUniqueId(),
        name: serverCategory.name,
        color: serverCategory.color,
        colorDark: serverCategory.colorDark,
        order: serverCategory.order,
        created: new Date().toISOString(),
        updated: new Date().toISOString(),
        deleted: null,
      }
    : null;

export class SyncListsService extends Syncer {
  private async saveServerListsToLocal(
    serverLists: ListDto[],
    localLists: ListInternalModel[],
  ): Promise<void> {
    await Promise.all(
      serverLists.map(async (serverList: ListDto): Promise<void> => {
        const isOnLocal: boolean = localLists.some(
          (localList: ListInternalModel): boolean => localList.id === serverList.id,
        );
        if (!isOnLocal) {
          await InternalStorageListsService.createList({
            name: serverList.name,
            id: serverList.id,
            localId: getUniqueId(),
            owner: serverList.owner ?? { name: "", id: "" },
            order: serverList.order,
            created: serverList.created ?? new Date().toISOString(),
            updated: serverList.updated ?? new Date().toISOString(),
            deleted: null,
          });
        }
      }),
    );
  }

  private async deleteServerLists(
    serverLists: ListDto[],
    localLists: ListInternalModel[],
  ): Promise<void> {
    await Promise.all(
      serverLists.map(async (serverList: ListDto): Promise<void> => {
        const isLocalVersionDeleted = localLists.some(
          (localList: ListInternalModel) =>
            localList.id === serverList.id && localList.deleted,
        );
        if (isLocalVersionDeleted) {
          await ListsService.deleteApiLists(serverList.id);
        }
      }),
    );
  }

  private async postLocalListsToServer(localLists: ListInternalModel[]): Promise<void> {
    await Promise.all(
      localLists.map(async (localList: ListInternalModel): Promise<void> => {
        const wasOnServer = !!localList.id;
        const isDeleted = !!localList.deleted;
        if (!wasOnServer && !isDeleted) {
          const postResult: ListDto = await ListsService.postApiLists({
            name: localList.name,
            created: localList.created,
            updated: localList.updated,
          });
          await InternalStorageListsService.updateList({
            localId: localList.localId,
            id: postResult.id,
            name: postResult.name,
            order: postResult.order,
            owner: postResult.owner,
            created: postResult.created,
            updated: postResult.updated,
          });
        }
      }),
    );
  }

  private async deleteLocalLists(
    serverLists: ListDto[],
    localLists: ListInternalModel[],
  ): Promise<void> {
    await Promise.all(
      localLists.map(async (localList: ListInternalModel): Promise<void> => {
        const wasOnServer = !!localList.id;
        const isOnServer = serverLists.some(
          (serverList: ListDto): boolean => serverList.id === localList.id,
        );
        if (wasOnServer && !isOnServer) {
          await InternalStorageListsService.deleteList(localList.localId, false);
        }
      }),
    );
  }

  private async updateLocalLists(
    serverLists: ListDto[],
    localLists: ListInternalModel[],
  ): Promise<void> {
    await Promise.all(
      localLists.map(async (localList: ListInternalModel): Promise<void> => {
        const serverList: ListDto | undefined = serverLists.find(
          (serverList: ListDto): boolean => serverList.id === localList.id,
        );
        if (!serverList?.updated || !localList.updated) {
          return;
        }
        const serverListUpdated: number = getDateValue(serverList.updated);
        const localListUpdated: number = getDateValue(localList.updated);
        const isServerListNewer: boolean = serverListUpdated - localListUpdated > 0;

        if (isServerListNewer && !localList.deleted) {
          await InternalStorageListsService.updateList({
            localId: localList.localId,
            name: serverList.name,
          });
        }
      }),
    );
  }

  private async updateServerLists(
    serverLists: ListDto[],
    localLists: ListInternalModel[],
  ): Promise<void> {
    await Promise.all(
      serverLists.map(async (serverList: ListDto): Promise<void> => {
        const localList: ListInternalModel | undefined = localLists.find(
          (localList: ListInternalModel): boolean => localList.id === serverList.id,
        );
        if (!localList?.updated || !serverList.updated) {
          return;
        }
        const localListUpdated: number = getDateValue(localList.updated);
        const serverListUpdated: number = getDateValue(serverList.updated);
        const isLocalListNewer: boolean = localListUpdated - serverListUpdated > 0;

        if (isLocalListNewer && !localList.deleted) {
          await ListsService.putApiLists(serverList.id, { name: localList.name });
        }
      }),
    );
  }

  private async cleanUpSoftDeletedLocalLists(
    localLists: ListInternalModel[],
  ): Promise<void> {
    await Promise.all(
      localLists.map(async (localList: ListInternalModel): Promise<void> => {
        if (localList.deleted) {
          await InternalStorageListsService.deleteList(localList.localId, false);
        }
      }),
    );
  }

  private async saveServerListItemsToLocal(
    serverListItems: ListItemDto[],
    localListItems: ListItemInternalModel[],
    localLists: ListInternalModel[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await Promise.all(
      serverListItems.map(async (serverListItem: ListItemDto): Promise<void> => {
        const isOnLocal: boolean = localListItems.some(
          (localListItem: ListItemInternalModel): boolean =>
            localListItem.id === serverListItem.id,
        );
        const localList = localLists.find(
          (localList: ListInternalModel): boolean =>
            localList.id === serverListItem.listId,
        );
        const localCategory = localCategories.find(
          (localCategory: ListItemCategoryInternalModel): boolean =>
            localCategory.id === serverListItem.category?.id,
        );
        if (!isOnLocal && localList) {
          await InternalStorageListsService.createListItem({
            name: serverListItem.name,
            id: serverListItem.id,
            localId: getUniqueId(),
            localListId: localList.localId,
            isCompleted: serverListItem.isCompleted,
            created: serverListItem.created || new Date().toISOString(),
            updated: serverListItem.updated || new Date().toISOString(),
            quantity: serverListItem.quantity ?? 1,
            order: Infinity,
            deleted: null,
            localCategory:
              localCategory ??
              convertServerListItemCategoryToLocal(serverListItem.category) ??
              null,
          });
        }
      }),
    );
  }

  private async deleteServerListItems(
    serverListItems: ListItemDto[],
    localListItems: ListItemInternalModel[],
  ): Promise<void> {
    await Promise.all(
      serverListItems.map(async (serverListItem: ListItemDto): Promise<void> => {
        const isLocalVersionDeleted = localListItems.some(
          (localListItem: ListItemInternalModel) =>
            localListItem.id === serverListItem.id && localListItem.deleted,
        );
        if (isLocalVersionDeleted) {
          await ListItemsService.deleteApiListsItem(
            serverListItem.listId,
            serverListItem.id,
          );
        }
      }),
    );
  }

  private async postLocalListItemsToServer(
    localListItems: ListItemInternalModel[],
    localLists: ListInternalModel[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await Promise.all(
      localListItems.map(async (localListItem: ListItemInternalModel): Promise<void> => {
        const wasOnServer = !!localListItem.id;
        const isDeleted = !!localListItem.deleted;
        const localList = localLists.find(
          (localList: ListInternalModel): boolean =>
            localList.localId === localListItem.localListId,
        );
        const localCategory = localCategories.find(
          (localCategory: ListItemCategoryInternalModel): boolean =>
            localCategory.localId === localListItem.localCategory?.localId,
        );
        if (!wasOnServer && !isDeleted && localList?.id) {
          const postResult: ListItemDto = await ListItemsService.postApiListsItem(
            localList.id,
            {
              name: localListItem.name,
              isCompleted: localListItem.isCompleted,
              categoryId: localCategory?.id,
              quantity: localListItem.quantity,
              created: localListItem.created,
              updated: localListItem.updated,
            },
          );
          await InternalStorageListsService.updateListItem({
            localId: localListItem.localId,
            id: postResult.id,
            updated: postResult.updated,
          });
        }
      }),
    );
  }

  private async deleteLocalListItems(
    serverListItems: ListItemDto[],
    localListItems: ListItemInternalModel[],
  ): Promise<void> {
    await Promise.all(
      localListItems.map(async (localListItem: ListItemInternalModel): Promise<void> => {
        const wasOnServer = !!localListItem.id;
        const isOnServer = serverListItems.some(
          (serverListItem: ListItemDto): boolean =>
            serverListItem.id === localListItem.id,
        );
        if (wasOnServer && !isOnServer) {
          await InternalStorageListsService.deleteListItem(localListItem.localId, false);
        }
      }),
    );
  }

  private async updateLocalListItems(
    serverListItems: ListItemDto[],
    localListItems: ListItemInternalModel[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await Promise.all(
      localListItems.map(async (localListItem: ListItemInternalModel): Promise<void> => {
        const serverListItem: ListItemDto | undefined = serverListItems.find(
          (serverListItem: ListItemDto): boolean =>
            serverListItem.id === localListItem.id,
        );
        if (!serverListItem?.updated || !localListItem.updated) {
          return;
        }
        const localCategory = localCategories.find(
          (localCategory: ListItemCategoryInternalModel): boolean =>
            localCategory.id === serverListItem.categoryId,
        );
        const serverItemUpdated: number = getDateValue(serverListItem.updated);
        const localItemUpdated: number = getDateValue(localListItem.updated);
        const isServerItemNewer: boolean = serverItemUpdated - localItemUpdated > 0;

        if (isServerItemNewer && !localListItem.deleted) {
          await InternalStorageListsService.updateListItem({
            localId: localListItem.localId,
            isCompleted: serverListItem.isCompleted,
            quantity: serverListItem.quantity,
            localCategory:
              localCategory ??
              convertServerListItemCategoryToLocal(serverListItem.category) ??
              null,
            updated: serverListItem.updated,
          });
        }
      }),
    );
  }

  private async updateServerListItems(
    serverListItems: ListItemDto[],
    localListItems: ListItemInternalModel[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await Promise.all(
      serverListItems.map(async (serverListItem: ListItemDto): Promise<void> => {
        const localListItem: ListItemInternalModel | undefined = localListItems.find(
          (localListItem: ListItemInternalModel): boolean =>
            localListItem.id === serverListItem.id,
        );
        if (!localListItem?.updated || !serverListItem.updated) {
          return;
        }
        const localCategory = localCategories.find(
          (localCategory: ListItemCategoryInternalModel): boolean =>
            localCategory.localId === localListItem.localCategory?.localId,
        );
        const localItemUpdated: number = new Date(localListItem.updated).valueOf();
        const serverItemUpdated: number = new Date(serverListItem.updated).valueOf();
        const isLocalItemNewer: boolean = localItemUpdated - serverItemUpdated > 0;

        if (isLocalItemNewer && !localListItem.deleted) {
          await ListItemsService.putApiListsItem(
            serverListItem.listId,
            serverListItem.id,
            {
              isCompleted: localListItem.isCompleted,
              categoryId: localCategory?.id ?? serverListItem.category?.id,
              quantity: localListItem.quantity,
              updated: localListItem.updated,
            },
          );
        }
      }),
    );
  }

  private async cleanUpSoftDeletedLocalListItems(
    localListsItems: ListItemInternalModel[],
  ): Promise<void> {
    await Promise.all(
      localListsItems.map(async (localListItem: ListItemInternalModel): Promise<void> => {
        if (localListItem.deleted) {
          await InternalStorageListsService.deleteListItem(localListItem.localId, false);
        }
      }),
    );
  }

  private async updateServerListsByShareKey(
    serverLists: ListDto[],
    localLists: ListInternalModel[],
  ): Promise<void> {
    await Promise.all(
      serverLists.map(async (serverList: ListDto): Promise<void> => {
        const localList: ListInternalModel | undefined = localLists.find(
          (localList: ListInternalModel): boolean => localList.id === serverList.id,
        );
        if (!localList?.updated || !serverList.updated) {
          return;
        }
        const localListUpdated: number = getDateValue(localList.updated);
        const serverListUpdated: number = getDateValue(serverList.updated);
        const isLocalListNewer: boolean = localListUpdated - serverListUpdated > 0;

        if (isLocalListNewer && !localList.deleted && localList.shareKey) {
          await ListsService.putApiListsShareKey(localList.shareKey, {
            name: localList.name,
          });
        }
      }),
    );
  }

  private async deleteServerListItemsByShareKey(
    serverListItems: ListItemDto[],
    localListItems: ListItemInternalModel[],
    localLists: ListInternalModel[],
  ): Promise<void> {
    await Promise.all(
      serverListItems.map(async (serverListItem: ListItemDto): Promise<void> => {
        const isLocalVersionDeleted = localListItems.some(
          (localListItem: ListItemInternalModel) =>
            localListItem.id === serverListItem.id && localListItem.deleted,
        );
        const localList = localLists.find(
          (localList: ListInternalModel) => localList.id === serverListItem.listId,
        );
        if (isLocalVersionDeleted && localList?.shareKey) {
          await ListItemsService.deleteApiListsShareKeyItem(
            localList.shareKey,
            serverListItem.id,
          );
        }
      }),
    );
  }

  private async postLocalListItemsToServerByShareKey(
    localListItems: ListItemInternalModel[],
    localLists: ListInternalModel[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await Promise.all(
      localListItems.map(async (localListItem: ListItemInternalModel): Promise<void> => {
        const wasOnServer = !!localListItem.id;
        const isDeleted = !!localListItem.deleted;
        const localList = localLists.find(
          (localList: ListInternalModel): boolean =>
            localList.localId === localListItem.localListId,
        );
        const localCategory = localCategories.find(
          (localCategory: ListItemCategoryInternalModel): boolean =>
            localCategory.localId === localListItem.localCategory?.localId,
        );
        if (!wasOnServer && !isDeleted && localList?.shareKey) {
          const postResult: ListItemDto = await ListItemsService.postApiListsShareKeyItem(
            localList.shareKey,
            {
              name: localListItem.name,
              isCompleted: localListItem.isCompleted,
              categoryId: localCategory?.id,
              quantity: localListItem.quantity,
              created: localListItem.created,
              updated: localListItem.updated,
            },
          );
          await InternalStorageListsService.updateListItem({
            localId: localListItem.localId,
            id: postResult.id,
            updated: postResult.updated,
          });
        }
      }),
    );
  }

  private async updateServerListItemsByShareKey(
    serverListItems: ListItemDto[],
    localListItems: ListItemInternalModel[],
    localLists: ListInternalModel[],
    localCategories: ListItemCategoryInternalModel[],
  ): Promise<void> {
    await Promise.all(
      serverListItems.map(async (serverListItem: ListItemDto): Promise<void> => {
        const localListItem: ListItemInternalModel | undefined = localListItems.find(
          (localListItem: ListItemInternalModel): boolean =>
            localListItem.id === serverListItem.id,
        );
        if (!localListItem?.updated || !serverListItem.updated) {
          return;
        }
        const localCategory = localCategories.find(
          (localCategory: ListItemCategoryInternalModel): boolean =>
            localCategory.localId === localListItem.localCategory?.localId,
        );
        const localList = localLists.find(
          (localList: ListInternalModel) => localList.id === serverListItem.listId,
        );
        const localItemUpdated: number = new Date(localListItem.updated).valueOf();
        const serverItemUpdated: number = new Date(serverListItem.updated).valueOf();
        const isLocalItemNewer: boolean = localItemUpdated - serverItemUpdated > 0;

        if (isLocalItemNewer && !localListItem.deleted && localList?.shareKey) {
          await ListItemsService.putApiListsShareKeyItem(
            localList.shareKey,
            serverListItem.id,
            {
              isCompleted: localListItem.isCompleted,
              quantity: localListItem.quantity,
              categoryId: localCategory?.id ?? serverListItem.category?.id,
              updated: localListItem.updated,
            },
          );
        }
      }),
    );
  }

  private async syncLists(): Promise<void> {
    const serverLists: ListDto[] = await ListsService.getApiLists();
    const localLists: ListInternalModel[] = await InternalStorageListsService.getLists();
    await this.saveServerListsToLocal(serverLists, localLists);
    await this.postLocalListsToServer(localLists);
    await this.deleteLocalLists(serverLists, localLists);
    await this.deleteServerLists(serverLists, localLists);
    await this.updateLocalLists(serverLists, localLists);
    await this.updateServerLists(serverLists, localLists);
    await this.cleanUpSoftDeletedLocalLists(localLists);
  }

  private async syncListItems(): Promise<void> {
    const serverLists: ListDetailedDto[] = await ListsService.getApiListsDetailed();
    const localLists: ListInternalModel[] = await InternalStorageListsService.getLists();
    const serverListItems: ListItemDto[] = serverLists.flatMap(
      (list: ListDetailedDto) => list.items ?? [],
    );
    const localListItems: ListItemInternalModel[] =
      await InternalStorageListsService.getAllListItems();
    const localCategories = await InternalStorageCategoriesService.getCategories();
    await this.saveServerListItemsToLocal(
      serverListItems,
      localListItems,
      localLists,
      localCategories,
    );
    await this.deleteLocalListItems(serverListItems, localListItems);
    await this.postLocalListItemsToServer(localListItems, localLists, localCategories);
    await this.deleteServerListItems(serverListItems, localListItems);
    await this.updateLocalListItems(serverListItems, localListItems, localCategories);
    await this.updateServerListItems(serverListItems, localListItems, localCategories);
    await this.cleanUpSoftDeletedLocalListItems(localListItems);
  }

  private async syncAnonListsAndItems(): Promise<void> {
    const localLists = await InternalStorageListsService.getLists();
    const localListItems = await InternalStorageListsService.getAllListItems();
    const sharedLocalLists = localLists.filter(
      (list) => !!list.shareKey && !list.deleted,
    );
    const sharedServerLists = await Promise.all(
      sharedLocalLists.map(async (localList) =>
        ListsService.getApiListsShareKey1(localList.shareKey!),
      ),
    );
    const sharedLocalListItems = localListItems.filter((listItem) =>
      sharedLocalLists.some((list) => list.localId === listItem.localListId),
    );
    const sharedServerListItems = sharedServerLists.flatMap((list) => list.items ?? []);
    const localCategories = await InternalStorageCategoriesService.getCategories();

    await this.updateLocalLists(sharedServerLists, localLists);
    await this.updateServerListsByShareKey(sharedServerLists, localLists);
    await this.saveServerListItemsToLocal(
      sharedServerListItems,
      sharedLocalListItems,
      sharedLocalLists,
      localCategories,
    );
    await this.deleteServerListItemsByShareKey(
      sharedServerListItems,
      sharedLocalListItems,
      sharedLocalLists,
    );
    await this.postLocalListItemsToServerByShareKey(
      sharedLocalListItems,
      sharedLocalLists,
      localCategories,
    );
    await this.deleteLocalListItems(sharedServerListItems, sharedLocalListItems);
    await this.updateLocalListItems(
      sharedServerListItems,
      sharedLocalListItems,
      localCategories,
    );
    await this.updateServerListItemsByShareKey(
      sharedServerListItems,
      sharedLocalListItems,
      sharedLocalLists,
      localCategories,
    );
    await this.cleanUpSoftDeletedLocalListItems(localListItems);
    await this.cleanUpSoftDeletedLocalLists(localLists);
  }

  public async sync(signedId: boolean, wereChanges: boolean): Promise<void> {
    if (!signedId) {
      console.log("Anonymous list items synchronization is running...");
      console.time("Anonymous list items synced");
      await this.syncAnonListsAndItems();
      console.timeEnd("Anonymous list items synced");
    } else if (wereChanges) {
      console.log("Lists synchronization is running...");
      console.time("Lists synced");
      const beforeSyncTime = new Date().toISOString();
      await this.syncLists();
      await this.syncListItems();
      await this.changesTracker.setLastSync(beforeSyncTime);
      console.timeEnd("Lists synced");
    }
    this.afterSyncCallback();
  }
}
