import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";

import { BaseInput } from "../../BaseInput/BaseInput";
import "./CreateListDialog.scss";
import { InputLabel } from "../../InputLabel/InputLabel";

export interface CreateListDialogProps {
  open: boolean;
  onClose: () => void;
  onAdd: (name: string) => void;
}

export const CreateListDialog = (props: CreateListDialogProps) => {
  const [open, setOpen] = useState(props.open);
  const [isInputOpen, setIsInputOpen] = useState(false);
  const [newListName, setNewListName] = useState("");

  const handleClose = () => {
    props.onClose();
  };

  const handleAdd = () => {
    if (newListName) {
      props.onAdd(newListName);
    }
  };

  useEffect(() => {
    setNewListName("");
    setOpen(props.open);
    if (props.open) {
      setTimeout(() => {
        setIsInputOpen(true);
      }, 100);
    } else {
      setIsInputOpen(false);
    }
  }, [props.open]);

  const changeEvent = (event: ChangeEvent<HTMLInputElement>) => {
    setNewListName(event.target.value.slice(0, 200));
  };

  return (
    <Dialog open={open} onClose={handleClose} className="add-list-dialog">
      <DialogTitle className="add-list-dialog-title">Новый список</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        className="add-list-dialog-close"
      >
        <Close />
      </IconButton>
      <DialogContent className="add-list-dialog-content">
        <InputLabel>Название списка</InputLabel>
        {isInputOpen && (
          <BaseInput
            placeholder="Укажите название"
            onChange={changeEvent}
            onEnter={handleAdd}
            autoFocus={true}
          />
        )}
      </DialogContent>
      <DialogActions className="add-list-dialog-actions">
        <Button
          className="add-list-dialog-actions-button"
          onClick={handleAdd}
          variant="contained"
          disabled={!newListName}
        >
          Создать
        </Button>
      </DialogActions>
    </Dialog>
  );
};
