export const Arrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M9.99992 12.6667L9.04992 11.7333L12.1166 8.66668H1.33325V7.33334H12.1166L9.06659 4.26668L9.99992 3.33334L14.6666 8.00001L9.99992 12.6667Z"
        fill="#08875D"
      />
    </svg>
  );
};
