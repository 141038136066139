/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddListRequest } from '../models/AddListRequest';
import type { AddSharedListRequest } from '../models/AddSharedListRequest';
import type { ListDetailedDto } from '../models/ListDetailedDto';
import type { ListDto } from '../models/ListDto';
import type { UpdateListRequest } from '../models/UpdateListRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ListsService {

    /**
     * @returns ListDto Success
     * @throws ApiError
     */
    public static getApiLists(): CancelablePromise<Array<ListDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/lists',
        });
    }

    /**
     * @param requestBody 
     * @returns ListDto Success
     * @throws ApiError
     */
    public static postApiLists(
requestBody?: AddListRequest,
): CancelablePromise<ListDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/lists',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ListDetailedDto Success
     * @throws ApiError
     */
    public static getApiListsDetailed(): CancelablePromise<Array<ListDetailedDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/lists/detailed',
        });
    }

    /**
     * @param listId 
     * @returns string Success
     * @throws ApiError
     */
    public static getApiListsShareKey(
listId: number,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/lists/{listId}/share-key',
            path: {
                'listId': listId,
            },
        });
    }

    /**
     * @param shareKey 
     * @returns ListDetailedDto Success
     * @throws ApiError
     */
    public static getApiListsShareKey1(
shareKey: string,
): CancelablePromise<ListDetailedDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/lists/share-key/{shareKey}',
            path: {
                'shareKey': shareKey,
            },
        });
    }

    /**
     * @param shareKey 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static putApiListsShareKey(
shareKey: string,
requestBody?: UpdateListRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/lists/share-key/{shareKey}',
            path: {
                'shareKey': shareKey,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiListsShared(
requestBody?: AddSharedListRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/lists/shared',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param listId 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static putApiLists(
listId: number,
requestBody?: UpdateListRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/lists/{listId}',
            path: {
                'listId': listId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param listId 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiLists(
listId: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/lists/{listId}',
            path: {
                'listId': listId,
            },
        });
    }

}
