import { AppBar, CircularProgress, SelectChangeEvent } from "@mui/material";
import Stack from "@mui/material/Stack";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

import { ListIcon } from "../../../assets/Icons/ListIcon";
import { AppMenu } from "../../../components/AppMenu/AppMenu";
import { AppTopBar } from "../../../components/AppTopBar/AppTopBar";
import { BaseButton } from "../../../components/BaseButton/BaseButton";
import { AcceptListDialog } from "../../../components/Dialogs/AcceptListDialog/AcceptListDialog";
import { CopyItemsDialog } from "../../../components/Dialogs/CopyItemsDialog/CopyItemsDialog";
import { CreateListDialog } from "../../../components/Dialogs/CreateListDialog/CreateListDialog";
import { DeleteDialog } from "../../../components/Dialogs/DeleteDialog/DeleteDialog";
import { EditListDialog } from "../../../components/Dialogs/EditListDialog/EditListDialog";
import { EditListItemDialog } from "../../../components/Dialogs/EditListItemDialog/EditListItemDialog";
import { ShareListDialog } from "../../../components/Dialogs/ShareListDialog/ShareListDialog";
import { TransferItemsDialog } from "../../../components/Dialogs/TransferItemsDialog/TransferItemsDialog";
import { EmptyContent } from "../../../components/EmptyContent/EmptyContent";
import { ListItemCreationToolbar } from "../../../components/ListItemCreationToolbar/ListItemCreationToolbar";
import { ListItems } from "../../../components/ListItems/ListItems";
import { ListsToolbar } from "../../../components/ListsToolbar/ListsToolbar";
import { ListInternalModel } from "../../../services/internalStorage/models/ListInternalModel";
import { ListItemCategoryInternalModel } from "../../../services/internalStorage/models/ListItemCategoryInternalModel";
import {
  enqueueSync,
  setFirstVisitDate,
} from "../../../store/application/applicationSlice";
import { selectApplicationState } from "../../../store/application/selectors";
import { logoutCleanup } from "../../../store/auth/authSlice";
import { selectAuthState } from "../../../store/auth/selectors";
import {
  addCategory,
  deleteOneCategory,
  fetchCategories,
  updateCategory,
} from "../../../store/categories/categoriesSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  deleteListItems,
  fetchListItems,
  updateListItem,
  getListByShareKey,
  acceptSharedList,
  createList,
  removeList,
  selectList,
  getShareListKey,
  changeListItemCategory,
  fetchLists,
  setShareListKeyForAccept,
  saveSelectedList,
  deleteCategoryFromListItems,
  copyListItems,
  setListToAccept,
  setShareListKeyForSharing,
  createListExample,
  updateList,
  insertItemsToList,
} from "../../../store/lists/listsSlice";
import {
  selectCompletedListItems,
  selectGroupedByCategoryListItems,
  selectListsState,
} from "../../../store/lists/selectors";
import { fetchPrompts } from "../../../store/prompts/promptsSlice";
import { URL_PARAMS } from "../../../utils/constants";
import normalizeText from "../../../utils/normalizeText";

export const ListsPage = () => {
  const dispatch = useAppDispatch();

  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    lists,
    listItems,
    selectedList,
    listToAccept,
    shareListKeyForSharing,
    shareListKeyForAccept,
    areListItemsLoading,
    areListsLoading,
  } = useAppSelector(selectListsState);
  const groupedByCategoryListItems = useAppSelector(selectGroupedByCategoryListItems);
  const completedListItems = useAppSelector(selectCompletedListItems);
  const { user, signedIn } = useAppSelector(selectAuthState);
  const { isOnline, firstVisitDate } = useAppSelector(selectApplicationState);
  const { prompts } = useAppSelector((state) => state.prompts);
  const { categories } = useAppSelector((state) => state.categories);
  const [isAddListDialogOpen, setIsAddListDialogOpen] = useState(false);
  const [isDeleteListDialogOpen, setIsDeleteListDialogOpen] = useState(false);
  const [isShareListDialogOpened, setIsShareListDialogOpened] = useState(false);
  const [isAppMenuOpened, setIsAppMenuOpened] = useState(false);
  const [isTextListItemCreationOpened, setIsTextListItemCreationOpened] = useState(false);
  const [isEditListItemDialogOpened, setIsEditListItemDialogOpened] = useState(false);
  const [listItemLocalIdToEdit, setListItemLocalIdToEdit] = useState(0);
  const [isTransferItemsDialogOpen, setIsTransferItemsDialogOpen] = useState(false);
  const [isCopyItemsDialogOpen, setIsCopyItemsDialogOpen] = useState(false);
  const [isListExampleCreating, setIsListExampleCreating] = useState(false);
  const [isEditListDialogOpen, setIsEditListDialogOpen] = useState(false);

  const handleCreateList = (name: string) => {
    setIsAddListDialogOpen(false);
    dispatch(createList(name));
  };

  const handleEditList = (editedList: ListInternalModel) => {
    dispatch(updateList({ localId: editedList.localId, name: editedList.name }));
  };

  const handleDeleteList = () => {
    setIsDeleteListDialogOpen(false);
    if (selectedList) {
      dispatch(removeList(selectedList.localId));
    }
  };

  const handleSelectList = (event: SelectChangeEvent) => {
    const listId = +event.target.value;
    dispatch(selectList({ localId: listId }));
  };

  const handleFetchShareListKey = (listId: number | null) => {
    if (listId === null) {
      dispatch(enqueueSync());
    } else {
      dispatch(getShareListKey(listId));
    }
  };

  const handleAddTextListItemClick = () => {
    setIsTextListItemCreationOpened(!isTextListItemCreationOpened);
  };

  const handleAddVoiceListItemClick = () => {
    if (browserSupportsSpeechRecognition) {
      SpeechRecognition.startListening({ language: "ru-RU" }).catch(console.log);
    }
  };

  const handleCancelListItemTextCreation = () => {
    setIsTextListItemCreationOpened(false);
  };

  const handleCancelListItemVoiceCreation = () => {
    SpeechRecognition.stopListening();
  };

  const handleListItemCreation = (input: string, quantity?: number) => {
    if (selectedList) {
      const items = input
        .split(" и ")
        .filter(normalizeText)
        .map((name) => ({ name, quantity: quantity || 1 }));
      dispatch(
        insertItemsToList({
          itemsData: items,
          listLocalId: selectedList.localId,
          categories,
          prompts,
          existingItems: listItems,
        }),
      );
    }
  };

  const handleCompleteListItem = (localId: number) => {
    if (selectedList) {
      dispatch(updateListItem({ localId, isCompleted: true }));
    }
  };

  const handleUnCompleteListItem = (localId: number) => {
    if (selectedList) {
      dispatch(updateListItem({ localId, isCompleted: false }));
    }
  };

  const handleDeleteAllListItems = () => {
    if (selectedList) {
      dispatch(
        deleteListItems({
          listItemsToDelete: completedListItems,
        }),
      );
    }
  };

  const handleLogout = () => {
    dispatch(logoutCleanup()).then(() => {
      window.location.reload();
    });
  };

  const handleOpenEditListItemDialog = (listItemLocalId: number) => {
    setListItemLocalIdToEdit(listItemLocalId);
    setIsEditListItemDialogOpened(true);
  };

  const handleCloseEditListItemDialog = () => {
    setListItemLocalIdToEdit(0);
    setIsEditListItemDialogOpened(false);
  };

  const handleListItemCategoryChange = (
    category: ListItemCategoryInternalModel | null,
  ) => {
    setIsEditListItemDialogOpened(false);
    dispatch(
      changeListItemCategory({ listItemLocalId: listItemLocalIdToEdit, category }),
    );
  };

  const handleCreateCategory = (name: string, color: string) => {
    dispatch(addCategory({ name, color }));
  };

  const handleEditCategory = async (editedCategory: ListItemCategoryInternalModel) => {
    await dispatch(updateCategory(editedCategory));
    if (selectedList) {
      dispatch(fetchListItems(selectedList.localId));
    }
  };
  const handleDeleteCategory = async (category: ListItemCategoryInternalModel) => {
    await dispatch(deleteOneCategory(category));
    await dispatch(deleteCategoryFromListItems(category.localId));
    if (selectedList) {
      dispatch(fetchListItems(selectedList.localId));
    }
  };

  const handleAcceptList = async () => {
    if (shareListKeyForAccept && listToAccept) {
      await dispatch(
        acceptSharedList({
          listToAccept: { ...listToAccept },
          shareListKeyForAccept,
          signedIn,
        }),
      );
    }
    dispatch(setShareListKeyForAccept(null));
    dispatch(setListToAccept(null));
  };

  const handleRejectList = () => {
    dispatch(setShareListKeyForAccept(null));
    dispatch(setListToAccept(null));
  };

  const handleTransferListItems = async (listLocalId: number) => {
    await dispatch(copyListItems({ listItems, listLocalId }));
    await dispatch(deleteListItems({ listItemsToDelete: listItems }));
    if (selectedList) {
      await dispatch(fetchListItems(selectedList.localId));
    }
  };

  const handleCopyListItems = async (listLocalId: number) => {
    await dispatch(copyListItems({ listItems, listLocalId }));
  };

  useEffect(() => {
    const shareListKeyForAccept = searchParams.get(URL_PARAMS.SHARE_LIST_KEY);
    if (shareListKeyForAccept) {
      dispatch(getListByShareKey(shareListKeyForAccept));
      searchParams.delete(URL_PARAMS.SHARE_LIST_KEY);
      setSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
    if (selectedList?.localId) {
      dispatch(fetchListItems(selectedList.localId));
      dispatch(saveSelectedList(selectedList.localId));
      dispatch(setShareListKeyForSharing(null));
    }
  }, [selectedList]);

  useEffect(() => {
    if (selectedList && !listening && transcript !== null && transcript.length > 1) {
      handleListItemCreation(normalizeText(transcript).replace(".", " "));
      resetTranscript();
    }
  }, [listening]);

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchLists());
    dispatch(fetchPrompts());
    dispatch(enqueueSync());
  }, [user]);

  useEffect(() => {
    if (firstVisitDate || signedIn) {
      return;
    }
    setIsListExampleCreating(true);
    if (prompts.length && categories.length) {
      dispatch(createListExample({ prompts, categories })).finally(() => {
        setIsListExampleCreating(false);
        dispatch(setFirstVisitDate(new Date().toISOString()));
      });
    }
  }, [prompts.length, categories.length, signedIn, firstVisitDate]);

  return (
    <Stack height={"100dvh"} overflow={"auto"}>
      <AppBar position="static">
        <AppTopBar isOnline={isOnline} onMenuClick={() => setIsAppMenuOpened(true)}>
          <ListsToolbar
            lists={lists}
            selectedList={selectedList}
            user={user}
            onAddListClick={() => setIsAddListDialogOpen(true)}
            onDeleteListClick={() => setIsDeleteListDialogOpen(true)}
            onTransferItemsClick={() => setIsTransferItemsDialogOpen(true)}
            onCopyItemsClick={() => setIsCopyItemsDialogOpen(true)}
            onShareListClick={() => setIsShareListDialogOpened(true)}
            onEditListClick={() => setIsEditListDialogOpen(true)}
            onSelectList={handleSelectList}
          />
        </AppTopBar>
        <AppMenu
          open={isAppMenuOpened}
          onOpen={() => setIsAppMenuOpened(true)}
          onClose={() => setIsAppMenuOpened(false)}
          user={user}
          onSignOutClick={handleLogout}
          onAddListClick={() => setIsAddListDialogOpen(true)}
        />
      </AppBar>
      <Stack component="main" overflow="auto" flexGrow="1">
        {selectedList && !areListItemsLoading && !isListExampleCreating && (
          <Stack flexGrow="1" alignItems="center" overflow="auto">
            <ListItems
              groupedByCategoryListItems={groupedByCategoryListItems}
              completedListItems={completedListItems}
              prompts={prompts}
              categories={categories}
              isListItemCreationOpened={isTextListItemCreationOpened}
              onListItemComplete={handleCompleteListItem}
              onListItemUnComplete={handleUnCompleteListItem}
              onDeleteAllListItems={handleDeleteAllListItems}
              onEditListItemClick={handleOpenEditListItemDialog}
              onSaveNewListItem={handleListItemCreation}
              onCancelListItemCreation={handleCancelListItemTextCreation}
            />
            <ListItemCreationToolbar
              listening={listening}
              onAddTextListItemClick={handleAddTextListItemClick}
              onAddVoiceListItemClick={handleAddVoiceListItemClick}
              onCancelVoiceListeningClick={handleCancelListItemVoiceCreation}
              browserSupportsSpeechRecognition={browserSupportsSpeechRecognition}
            />
          </Stack>
        )}
        {!selectedList && !areListsLoading && !isListExampleCreating && (
          <EmptyContent
            image={<ListIcon />}
            title="Добавьте список"
            subtitle="Создайте новый список чтобы иметь возможность добавлять продукты"
            button={
              <BaseButton onClick={() => setIsAddListDialogOpen(true)}>
                Создать список
              </BaseButton>
            }
          />
        )}
        {isListExampleCreating && <EmptyContent image={<CircularProgress />} />}
      </Stack>
      <CreateListDialog
        open={isAddListDialogOpen}
        onClose={() => setIsAddListDialogOpen(false)}
        onAdd={handleCreateList}
      />
      <AcceptListDialog
        open={!!listToAccept}
        onAccept={handleAcceptList}
        onReject={handleRejectList}
        list={listToAccept}
      />
      <EditListItemDialog
        open={isEditListItemDialogOpened}
        onClose={handleCloseEditListItemDialog}
        onCategoryChange={handleListItemCategoryChange}
        onCategoryCreate={handleCreateCategory}
        onCategoryEdit={handleEditCategory}
        onCategoryDelete={handleDeleteCategory}
        categories={categories}
        listItem={listItems.find((li) => li.localId === listItemLocalIdToEdit)}
      />
      {selectedList && (
        <>
          <DeleteDialog
            open={isDeleteListDialogOpen}
            name={selectedList.name}
            onClose={() => setIsDeleteListDialogOpen(false)}
            onOk={handleDeleteList}
            type="cписок"
          />
          <ShareListDialog
            open={isShareListDialogOpened}
            onClose={() => setIsShareListDialogOpened(false)}
            signedIn={signedIn}
            isOnline={isOnline}
            onFetchShareKey={handleFetchShareListKey}
            shareListKey={shareListKeyForSharing}
            selectedList={selectedList}
          />
          <TransferItemsDialog
            open={isTransferItemsDialogOpen}
            onClose={() => setIsTransferItemsDialogOpen(false)}
            onConfirm={handleTransferListItems}
            lists={lists}
            itemsCount={listItems.length}
            user={user}
          />
          <CopyItemsDialog
            open={isCopyItemsDialogOpen}
            onClose={() => setIsCopyItemsDialogOpen(false)}
            onConfirm={handleCopyListItems}
            lists={lists}
            itemsCount={listItems.length}
            user={user}
          />
          <EditListDialog
            open={isEditListDialogOpen}
            onClose={() => setIsEditListDialogOpen(false)}
            listToEdit={selectedList}
            onConfirm={handleEditList}
          />
        </>
      )}
    </Stack>
  );
};
