import { Button, Typography } from "@mui/material";
import { ReactNode } from "react";

import { Download } from "../../assets/Icons/Download";
import "./InstallButton.scss";

interface AlertProps {
  onClick?: () => void;
  children?: ReactNode;
  occasion?: "new-year" | "none";
}

export const InstallButton = (props: AlertProps) => {
  return (
    <Button
      className={`install-button install-button_occasion_${props.occasion || "none"}`}
      onClick={props.onClick}
    >
      <Download />
      <Typography className="install-button-text">{props.children}</Typography>
    </Button>
  );
};
