import "./RecipeItems.scss";
import { Button, List, ListItem } from "@mui/material";
import React from "react";

import { Plus } from "../../assets/Icons/Plus";
import { RadioUnchecked } from "../../assets/Icons/RadioUnchecked";
import { ListItemPromptInternalModel } from "../../services/internalStorage/models/ListItemPromptInternalModel";
import { ListItemAutocomplete } from "../ListItemAutocomplete/ListItemAutocomplete";
import { RecipeItem } from "../RecipeItem/RecipeItem";

interface RecipeItemsProps {
  items: { name: string; quantity: number }[];
  onItemCreate?: (listItemName: string, quantity: number) => void;
  prompts?: ListItemPromptInternalModel[];
  disabled?: boolean;
  onItemQuantityChange?: (id: number, quantity: number) => void;
  onDeleteItemClick?: (id: number) => void;
  onAddToListClick?: () => void;
}

export const RecipeItems = (props: RecipeItemsProps) => {
  return (
    <List className={`recipe-items ${props.disabled ? "recipe-items_disabled" : ""}`}>
      {props.items.map((item, index) => (
        <ListItem key={index}>
          <RecipeItem
            id={index}
            name={item.name}
            quantity={item.quantity}
            onQuantityChange={props.onItemQuantityChange}
            onDelete={props.onDeleteItemClick}
            disabled={props.disabled}
          />
        </ListItem>
      ))}
      {props.onItemCreate && (
        <ListItem className="recipe-items-autocomplete">
          <RadioUnchecked />
          <ListItemAutocomplete prompts={props.prompts} onChange={props.onItemCreate} />
        </ListItem>
      )}
      {props.disabled && (
        <Button
          variant="contained"
          className="recipe-items-button"
          onClick={props.onAddToListClick}
        >
          <Plus />
          Добавить в список
        </Button>
      )}
    </List>
  );
};
