import { Box, IconButton, Typography } from "@mui/material";

import "./RecipeItem.scss";
import { Delete } from "../../assets/Icons/Delete";
import { RadioChecked } from "../../assets/Icons/RadioChecked";
import { QuantityInput } from "../QuantityInput/QuantityInput";

interface RecipeItemProps {
  id: number;
  name: string;
  quantity: number;
  onQuantityChange?: (id: number, quantity: number) => void;
  onDelete?: (id: number) => void;
  disabled?: boolean;
}

export const RecipeItem = (props: RecipeItemProps) => {
  const handleQuantityChange = (value: string) => {
    if (props.onQuantityChange) {
      props.onQuantityChange(props.id, +value);
    }
  };

  const handleDeleteClick = () => {
    if (props.onDelete) {
      props.onDelete(props.id);
    }
  };

  return (
    <Box className="recipe-item">
      <Box className="recipe-item-name">
        <RadioChecked color="#8399A8" />
        <Typography>{props.name}</Typography>
      </Box>
      <Box className="recipe-item-controls">
        <QuantityInput
          disabled={props.disabled}
          className="recipe-item-controls-quantity"
          value={props.quantity.toString()}
          onChange={handleQuantityChange}
        />
        {props.onDelete && (
          <IconButton className="recipe-item-controls-delete" onClick={handleDeleteClick}>
            <Delete />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
