import { Button, DialogActions, TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { PickersActionBarProps } from "@mui/x-date-pickers/PickersActionBar";
import "./TimeSelect.scss";
import { Dayjs } from "dayjs";

interface TimeSelectProps {
  value: Dayjs | null;
  onChange: (value: Dayjs | null) => void;
  label: string;
}

export const TimeSelect = (props: TimeSelectProps) => {
  return (
    <TimePicker
      label={props.label}
      value={props.value}
      className="time-select"
      onChange={props.onChange}
      renderInput={(params) => <TextField {...params} />}
      ampm={false}
      components={{
        ActionBar: ({ onAccept, onCancel }: PickersActionBarProps) => {
          return (
            <DialogActions>
              <Button onClick={onCancel}>Отмена</Button>
              <Button onClick={onAccept}>Готово</Button>
            </DialogActions>
          );
        },
      }}
    />
  );
};
