import { Box, CircularProgress, Typography } from "@mui/material";

import "./ImageUploader.scss";

import { Upload } from "../../assets/Icons/Upload";
import EditIcon from "../../assets/Icons/edit.svg";
import { ImageInput } from "../ImageInput/ImageInput";

interface ImageUploaderProps {
  id?: string;
  onChange: (file: File) => void;
  imageUrl?: string;
  isUploading?: boolean;
}

export const ImageUploader = (props: ImageUploaderProps) => {
  const idTag = `image-uploader${props.id ?? ""}`;

  return (
    <Box className="image-uploader">
      <ImageInput id={idTag} onChange={props.onChange} maxWidth={1280} maxHeight={720} />
      {props.isUploading && <CircularProgress />}
      {!props.isUploading && props.imageUrl && (
        <Box className="image-uploader-box">
          <Box
            className="image-uploader-box-img"
            component="img"
            src={props.imageUrl}
            alt="recipe images"
          />
          <label className="image-uploader-box-label" htmlFor={idTag}>
            <img src={EditIcon} alt="edit" />
          </label>
        </Box>
      )}
      {!props.isUploading && !props.imageUrl && (
        <label className="image-uploader-select" htmlFor={idTag}>
          <Upload />
          <Typography>Добавить фото</Typography>
        </label>
      )}
    </Box>
  );
};
