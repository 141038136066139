export const AddNew = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M9.93337 16.3333H12.0667V12.0667H16.3334V9.93333H12.0667V5.66666H9.93337V9.93333H5.66671V12.0667H9.93337V16.3333ZM11 21.6667C9.52448 21.6667 8.13782 21.3867 6.84004 20.8267C5.54226 20.2667 4.41337 19.5067 3.45337 18.5467C2.49337 17.5867 1.73337 16.4578 1.17337 15.16C0.613374 13.8622 0.333374 12.4755 0.333374 11C0.333374 9.52444 0.613374 8.13777 1.17337 6.83999C1.73337 5.54222 2.49337 4.41333 3.45337 3.45333C4.41337 2.49333 5.54226 1.73333 6.84004 1.17333C8.13782 0.613328 9.52448 0.333328 11 0.333328C12.4756 0.333328 13.8623 0.613328 15.16 1.17333C16.4578 1.73333 17.5867 2.49333 18.5467 3.45333C19.5067 4.41333 20.2667 5.54222 20.8267 6.83999C21.3867 8.13777 21.6667 9.52444 21.6667 11C21.6667 12.4755 21.3867 13.8622 20.8267 15.16C20.2667 16.4578 19.5067 17.5867 18.5467 18.5467C17.5867 19.5067 16.4578 20.2667 15.16 20.8267C13.8623 21.3867 12.4756 21.6667 11 21.6667ZM11 19.5333C13.3823 19.5333 15.4 18.7067 17.0534 17.0533C18.7067 15.4 19.5334 13.3822 19.5334 11C19.5334 8.61777 18.7067 6.59999 17.0534 4.94666C15.4 3.29333 13.3823 2.46666 11 2.46666C8.61782 2.46666 6.60004 3.29333 4.94671 4.94666C3.29337 6.59999 2.46671 8.61777 2.46671 11C2.46671 13.3822 3.29337 15.4 4.94671 17.0533C6.60004 18.7067 8.61782 19.5333 11 19.5333Z"
        fill="#04724D"
      />
    </svg>
  );
};
