import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";

import "./InputError.scss";

import { Info } from "../../assets/Icons/Info";

interface InputErrorProps {
  className?: string;
  type?: "success" | "info" | "warning" | "danger";
  children?: ReactNode;
}

export const InputError = (props: InputErrorProps) => {
  return (
    <Box
      className={`
        input-error
        ${props.type && `input-error--${props.type}`}
        ${props.className ? props.className : ""}
      `}
    >
      <Box
        className={`input-error-icon ${props.type && `input-error-icon--${props.type}`}`}
      >
        <Info />
      </Box>
      <Typography className="input-error-text">{props.children}</Typography>
    </Box>
  );
};
