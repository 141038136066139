export default {
  ApiUrl: window?.env?.ApiUrl ?? "",
  TermsUrl: window?.env?.TermsUrl ?? "",
  PrivacyUrl: window?.env?.PrivacyUrl ?? "",
  GoogleApiClientId: window?.env?.GoogleApiClientId ?? "",
  SyncListsInterval: window?.env?.SyncListsInterval ?? 5000,
  SyncCategoriesInterval: window?.env?.SyncCategoriesInterval ?? 30000,
  SyncPromptsInterval: window?.env?.SyncPromptsInterval ?? 30000,
  ShowInstallButtonInterval: window?.env?.ShowInstallButtonInterval ?? 172800000,
  ShowInstallButtonDelay: window?.env?.ShowInstallButtonDelay ?? 30000,
  Debug: window?.env?.Debug ?? false,
  YandexMetrikaCode: window?.env?.YandexMetrikaCode ?? 98330076,
  ShowRegistrationInterval: window?.env?.ShowRegistrationInterval ?? 86400000,
  ShowRegistrationDelay: window?.env?.ShowRegistrationDelay ?? 0,
  Occasion: window?.env?.Occasion ?? "none",
};
