import { Box, IconButton } from "@mui/material";
import { ChangeEvent, MouseEvent, useEffect, useState } from "react";

import { Minus } from "../../assets/Icons/Minus";
import { Plus } from "../../assets/Icons/Plus";

import "./QuantityInput.scss";

interface QuantityInputProps {
  value: string;
  onChange?: (value: string) => void;
  className?: string;
  onEnter?: () => void;
  autofocus?: boolean;
  maxSymbols?: number;
  disabled?: boolean;
}

export const QuantityInput = (props: QuantityInputProps) => {
  const [preValue, setPreValue] = useState<string>(props.value);

  useEffect(() => {
    if (!props.onChange) {
      return;
    }
    if (+preValue < 1) {
      props.onChange("");
    } else {
      props.onChange(preValue.slice(0, props.maxSymbols ?? 5));
    }
  }, [preValue, props.maxSymbols]);

  const handleMinusClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setPreValue((+props.value - 1).toString());
  };

  const handlePlusClick = () => {
    setPreValue((+props.value + 1).toString());
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    setPreValue(e.target.value);
  };

  return (
    <Box className={`quantity-input ${props.className ? props.className : ""}`}>
      {!props.disabled && (
        <IconButton onClick={handleMinusClick}>
          <Minus />
        </IconButton>
      )}
      <input
        type="number"
        pattern="[0-9]*"
        inputMode="numeric"
        value={props.value}
        onChange={handleInputChange}
        onKeyDown={(e) => {
          if (e.key === "Enter" && props.onEnter) {
            props.onEnter();
          }
        }}
        autoFocus={props.autofocus}
        disabled={props.disabled}
      />
      {!props.disabled && (
        <IconButton onClick={handlePlusClick}>
          <Plus />
        </IconButton>
      )}
    </Box>
  );
};
