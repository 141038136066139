import "./RecipesPage.scss";
import Stack from "@mui/material/Stack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AddToListDialog } from "../../../components/Dialogs/AddToListDialog/AddToListDialog";
import { CreateRecipeDialog } from "../../../components/Dialogs/CreateRecipeDialog/CreateRecipeDialog";
import { RecipeDetailedDialog } from "../../../components/Dialogs/RecipeDetailedDialog/RecipeDetailedDialog";
import { RecipeFormData } from "../../../components/RecipeForm/RecipeForm";
import { RecipesHeading } from "../../../components/RecipesHeading/RecipesHeading";
import { RecipesList } from "../../../components/RecipesList/RecipesList";
import { RecipesSearch } from "../../../components/RecipesSearch/RecipesSearch";
import { RecipesToListBar } from "../../../components/RecipesToListBar/RecipesToListBar";
import { selectApplicationState } from "../../../store/application/selectors";
import { selectAuthState } from "../../../store/auth/selectors";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { selectListsState } from "../../../store/lists/selectors";
import {
  addRecipesToList,
  createRecipe,
  deleteRecipe,
  editRecipe,
  fetchRecipe,
  fetchRecipes,
  setSelectedRecipes,
  toggleRecipe,
} from "../../../store/recipes/recipesSlice";
import {
  selectRecipesState,
  selectSortedRecipes,
} from "../../../store/recipes/selectors";
import { Paths } from "../../../utils/constants";

export const RecipesPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isOnline } = useAppSelector(selectApplicationState);
  const { signedIn, user } = useAppSelector(selectAuthState);
  const {
    detailedRecipe,
    isDetailedRecipeLoading,
    areRecipesLoading,
    isRecipeUpdating,
    selectedRecipes,
  } = useAppSelector(selectRecipesState);
  const { lists } = useAppSelector(selectListsState);
  const sortedRecipes = useAppSelector(selectSortedRecipes);
  const [isCreateRecipeDialogOpen, setIsCreateRecipeDialogOpen] = useState(false);
  const [isDetailedRecipeDialogOpen, setIsDetailedRecipeDialogOpen] = useState(false);
  const [isAddToListDialogOpen, setIsAddToListDialogOpen] = useState(false);
  const [isEditRecipe, setIsEditRecipe] = useState(false);

  useEffect(() => {
    dispatch(fetchRecipes());
  }, []);

  const handleRecipeClick = (recipeId: number) => {
    setIsDetailedRecipeDialogOpen(true);
    dispatch(fetchRecipe(recipeId));
  };

  const handleCreateRecipeFormSubmit = async (recipeData: RecipeFormData) => {
    await dispatch(createRecipe(recipeData));
    setIsCreateRecipeDialogOpen(false);
  };

  const handleEditRecipeClick = (recipeId: number) => {
    setIsEditRecipe(true);
    setIsDetailedRecipeDialogOpen(true);
    dispatch(fetchRecipe(recipeId));
  };

  const handleEditRecipeFormSubmit = async (id: number, newData: RecipeFormData) => {
    await dispatch(editRecipe({ id, newData }));
  };

  const handleDeleteRecipeClick = (id: number) => {
    dispatch(deleteRecipe(id));
  };

  const handleToggleRecipe = (id: number) => {
    dispatch(toggleRecipe({ recipeId: id }));
  };

  const handleCancelSelection = () => {
    dispatch(setSelectedRecipes([]));
  };

  const handleAddSelectedRecipesToListConfirm = (listLocalId: number) => {
    dispatch(addRecipesToList({ recipesIds: selectedRecipes, listLocalId }));
    dispatch(setSelectedRecipes([]));
  };

  const handleAddDetailedRecipeToListClick = () => {
    if (detailedRecipe?.id) {
      dispatch(setSelectedRecipes([detailedRecipe.id]));
      setIsAddToListDialogOpen(true);
    }
  };

  const handleAddToListDialogClose = () => {
    setIsAddToListDialogOpen(false);
    dispatch(setSelectedRecipes([]));
  };

  return (
    <Stack className="recipes">
      <RecipesHeading
        onCreateRecipeClick={() => setIsCreateRecipeDialogOpen(true)}
        isCreateDisabled={!isOnline || !signedIn}
        isSelect={!!selectedRecipes.length}
        onCancelSelectClick={handleCancelSelection}
      />
      <RecipesSearch
        onSearch={(query) => dispatch(fetchRecipes(query))}
        disabled={!isOnline || !signedIn}
      />
      <RecipesList
        recipes={sortedRecipes}
        areRecipesLoading={areRecipesLoading}
        isOnline={isOnline}
        signedIn={signedIn}
        selectedRecipes={selectedRecipes}
        isSelection={!!selectedRecipes.length}
        onRecipeClick={handleRecipeClick}
        onCreateRecipeClick={() => setIsCreateRecipeDialogOpen(true)}
        onDeleteRecipeClick={handleDeleteRecipeClick}
        onToggleRecipeClick={handleToggleRecipe}
        onEditRecipeClick={handleEditRecipeClick}
        onLoginClick={() => navigate(Paths.LOGIN_HOME)}
      />
      {!!selectedRecipes.length && (
        <RecipesToListBar
          count={selectedRecipes.length}
          onAddToList={() => setIsAddToListDialogOpen(true)}
        />
      )}
      <CreateRecipeDialog
        open={isCreateRecipeDialogOpen}
        onClose={() => setIsCreateRecipeDialogOpen(false)}
        onFromSubmit={handleCreateRecipeFormSubmit}
      />
      <RecipeDetailedDialog
        open={isDetailedRecipeDialogOpen}
        onClose={() => setIsDetailedRecipeDialogOpen(false)}
        recipe={detailedRecipe}
        isEdit={isEditRecipe}
        onEditRecipeToggle={(isEdit) => setIsEditRecipe(isEdit)}
        isRecipeLoading={isDetailedRecipeLoading}
        isRecipeUpdating={isRecipeUpdating}
        onRecipeChange={handleEditRecipeFormSubmit}
        onAddToListClick={handleAddDetailedRecipeToListClick}
      />
      <AddToListDialog
        open={isAddToListDialogOpen}
        lists={lists}
        onClose={handleAddToListDialogClose}
        onConfirm={handleAddSelectedRecipesToListConfirm}
        user={user}
      />
    </Stack>
  );
};
