export const HorizontalKebab = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
    >
      <path
        d="M5.25008 11.0833C4.81466 11.0833 4.44192 10.9283 4.13185 10.6182C3.82178 10.3082 3.66675 9.93541 3.66675 9.5C3.66675 9.06458 3.82178 8.69184 4.13185 8.38177C4.44192 8.0717 4.81466 7.91666 5.25008 7.91666C5.6855 7.91666 6.05824 8.0717 6.36831 8.38177C6.67838 8.69184 6.83341 9.06458 6.83341 9.5C6.83341 9.93541 6.67838 10.3082 6.36831 10.6182C6.05824 10.9283 5.6855 11.0833 5.25008 11.0833ZM10.0001 11.0833C9.56466 11.0833 9.19192 10.9283 8.88185 10.6182C8.57178 10.3082 8.41675 9.93541 8.41675 9.5C8.41675 9.06458 8.57178 8.69184 8.88185 8.38177C9.19192 8.0717 9.56466 7.91666 10.0001 7.91666C10.4355 7.91666 10.8082 8.0717 11.1183 8.38177C11.4284 8.69184 11.5834 9.06458 11.5834 9.5C11.5834 9.93541 11.4284 10.3082 11.1183 10.6182C10.8082 10.9283 10.4355 11.0833 10.0001 11.0833ZM14.7501 11.0833C14.3147 11.0833 13.9419 10.9283 13.6319 10.6182C13.3218 10.3082 13.1667 9.93541 13.1667 9.5C13.1667 9.06458 13.3218 8.69184 13.6319 8.38177C13.9419 8.0717 14.3147 7.91666 14.7501 7.91666C15.1855 7.91666 15.5582 8.0717 15.8683 8.38177C16.1784 8.69184 16.3334 9.06458 16.3334 9.5C16.3334 9.93541 16.1784 10.3082 15.8683 10.6182C15.5582 10.9283 15.1855 11.0833 14.7501 11.0833Z"
        fill="#1B2227"
      />
    </svg>
  );
};
