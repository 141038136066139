import { Close } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useState } from "react";

import { ListDetailedDto } from "../../../services/openapi";
import { InputLabel } from "../../InputLabel/InputLabel";
import "./AcceptListDialog.scss";

export interface AcceptListDialogProps {
  open: boolean;
  onAccept: () => Promise<void>;
  onReject: () => void;
  list: ListDetailedDto | null;
}

export const AcceptListDialog = (props: AcceptListDialogProps) => {
  const [isAccepting, setIsAccepting] = useState(false);

  const handleAccept = async () => {
    setIsAccepting(true);
    await props.onAccept();
    setIsAccepting(false);
  };

  return (
    <Dialog open={props.open} onClose={props.onReject} className="accept-list-dialog">
      <DialogTitle className="accept-list-dialog-title">Приглашение в список</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={props.onReject}
        className="accept-list-dialog-close"
      >
        <Close />
      </IconButton>
      <DialogContent className="accept-list-dialog-content">
        {isAccepting ? (
          <Box className="accept-list-dialog-content-loader">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Typography className="accept-list-dialog-content-text">
              С вами хотят поделиться списком &quot;{props.list?.name}&quot;. Хотите
              принять приглашение?
            </Typography>
            <InputLabel>Пользователь</InputLabel>
            <Box className="accept-list-dialog-content-user">
              <Avatar
                className="accept-list-dialog-content-user-avatar"
                src={props.list?.owner?.profileUrl ?? ""}
              />
              <Typography className="accept-list-dialog-content-user-name">
                {props.list?.owner?.name}
              </Typography>
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions className="accept-list-dialog-actions">
        <Button onClick={props.onReject} className="accept-list-dialog-actions-cancel">
          Отменить
        </Button>
        <Button
          onClick={handleAccept}
          variant="contained"
          className="accept-list-dialog-actions-accept"
          disabled={isAccepting}
        >
          Принять
        </Button>
      </DialogActions>
    </Dialog>
  );
};
