import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import "@fontsource/inter/900.css";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useEffect, useMemo } from "react";
import { usePageVisibility } from "react-page-visibility";
import { Route, Routes } from "react-router-dom";

import "dayjs/locale/ru";
import config from "../appConfig/config";
import { InstallPwaDialog } from "../components/Dialogs/InstallPwaDialog/InstallPwaDialog";
import { YandexMetrika } from "../components/YandexMetrika/YandexMetrika";
import { useInstallDialog } from "../hooks/useInstallDialog";
import {
  enqueueSync,
  fetchInstallDialogToggle,
  runSync,
  stopSync,
} from "../store/application/applicationSlice";
import { checkSignIn, getAndSaveUserProfile } from "../store/auth/authSlice";
import { selectAuthState } from "../store/auth/selectors";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { getDesignTokens } from "../theme";
import { Paths } from "../utils/constants";

import { AdminPage } from "./pages/AdminPage/AdminPage";
import { FutureFeaturesPage } from "./pages/FutureFeaturesPage/FutureFeaturesPage";
import { ListsPage } from "./pages/ListsPage/ListsPage";
import { LoginHomePage } from "./pages/LoginHomePage/LoginHomePage";
import { LoginPage } from "./pages/LoginPage/LoginPage";
import { RecipesPage } from "./pages/RecipesPage/RecipesPage";
import { SettingsPage } from "./pages/SettingsPage/SettingsPage";
import { SignUpPage } from "./pages/SignUpPage/SignUpPage";
import { SupportPage } from "./pages/SupportPage/SupportPage";

export const App = () => {
  const dispatch = useAppDispatch();
  const { signedIn } = useAppSelector(selectAuthState);
  const isPageVisible = usePageVisibility();
  const { isInstallDialogOpen, setIsInstallDialogOpen, install } = useInstallDialog();

  useEffect(() => {
    dispatch(checkSignIn());
    dispatch(fetchInstallDialogToggle());
  }, []);

  useEffect(() => {
    if (signedIn) {
      dispatch(getAndSaveUserProfile());
    }
  }, [signedIn]);

  useEffect(() => {
    if (isPageVisible) {
      dispatch(runSync(config.SyncListsInterval));
      dispatch(enqueueSync());
    } else {
      dispatch(stopSync());
    }
  }, [isPageVisible]);

  const theme = useMemo(() => createTheme(getDesignTokens("light")), []);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
        <CssBaseline />
        <GoogleOAuthProvider clientId={config.GoogleApiClientId}>
          <Routes>
            <Route path="/" element={<ListsPage />} />
            <Route path={Paths.SUPPORT} element={<SupportPage />} />
            <Route path={Paths.LOGIN_HOME} element={<LoginHomePage />} />
            <Route path={Paths.LOGIN} element={<LoginPage />} />
            <Route path={Paths.SIGN_UP} element={<SignUpPage />} />
            <Route path={Paths.FUTURE_FEATURES} element={<FutureFeaturesPage />} />
            <Route path={Paths.SETTINGS} element={<SettingsPage />} />
            <Route path={Paths.RECIPES} element={<RecipesPage />} />
            <Route path={Paths.ADMIN} element={<AdminPage />} />
          </Routes>
          <InstallPwaDialog
            open={isInstallDialogOpen}
            onInstall={install}
            onClose={() => setIsInstallDialogOpen(false)}
          />
        </GoogleOAuthProvider>
        <YandexMetrika code={config.YandexMetrikaCode} />
      </LocalizationProvider>
    </ThemeProvider>
  );
};
