export const Clock = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M10.6999 11.1333L11.6333 10.2L9.16659 7.73333V4.66667H7.83325V8.26667L10.6999 11.1333ZM8.49992 14.6667C7.5777 14.6667 6.71103 14.4917 5.89992 14.1417C5.08881 13.7917 4.38325 13.3167 3.78325 12.7167C3.18325 12.1167 2.70825 11.4111 2.35825 10.6C2.00825 9.78889 1.83325 8.92222 1.83325 8C1.83325 7.07778 2.00825 6.21111 2.35825 5.4C2.70825 4.58889 3.18325 3.88333 3.78325 3.28333C4.38325 2.68333 5.08881 2.20833 5.89992 1.85833C6.71103 1.50833 7.5777 1.33333 8.49992 1.33333C9.42214 1.33333 10.2888 1.50833 11.0999 1.85833C11.911 2.20833 12.6166 2.68333 13.2166 3.28333C13.8166 3.88333 14.2916 4.58889 14.6416 5.4C14.9916 6.21111 15.1666 7.07778 15.1666 8C15.1666 8.92222 14.9916 9.78889 14.6416 10.6C14.2916 11.4111 13.8166 12.1167 13.2166 12.7167C12.6166 13.3167 11.911 13.7917 11.0999 14.1417C10.2888 14.4917 9.42214 14.6667 8.49992 14.6667ZM8.49992 13.3333C9.9777 13.3333 11.236 12.8139 12.2749 11.775C13.3138 10.7361 13.8333 9.47778 13.8333 8C13.8333 6.52222 13.3138 5.26389 12.2749 4.225C11.236 3.18611 9.9777 2.66667 8.49992 2.66667C7.02214 2.66667 5.76381 3.18611 4.72492 4.225C3.68603 5.26389 3.16659 6.52222 3.16659 8C3.16659 9.47778 3.68603 10.7361 4.72492 11.775C5.76381 12.8139 7.02214 13.3333 8.49992 13.3333Z"
        fill="#1B2227"
      />
    </svg>
  );
};
