import { Box, Button, IconButton, Typography } from "@mui/material";
import "./WarningRegistrationAlert.scss";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import Close from "../../assets/Icons/close.svg";
import Info from "../../assets/Icons/warning.svg";
import { Paths } from "../../utils/constants";
export type WarningRegistrationAlert = {
  interval: number;
  delay: number;
  onShouldShow: (interval: number) => Promise<boolean>;
  onAfterShow: () => void;
};

export const WarningRegistrationAlert = (props: WarningRegistrationAlert) => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    setTimeout(async () => {
      const shouldShow = await props.onShouldShow(props.interval);
      setIsVisible(shouldShow);
    }, props.delay);
  }, [props.interval, props.delay, props.onShouldShow]);

  const handleClose = () => {
    props.onAfterShow();
    setIsVisible(false);
  };

  return (
    <Box className={"registration-alert" + (isVisible ? " active" : "")}>
      <Box className="registration-alert-body">
        <img src={Info} alt="" className="registration-alert-body-info" />
        <Box className="registration-alert-body-content">
          <h4 className="registration-alert-body-content-title">Требуется авторизация</h4>
          <Typography className="registration-alert-body-content-description">
            Вы можете потерять свои данные, требуется авторизоваться в приложении
          </Typography>
        </Box>
        <IconButton className="registration-alert-body-close" onClick={handleClose}>
          <img src={Close} alt="close" />
        </IconButton>
      </Box>
      <Box className="registration-alert-controls">
        <Button onClick={handleClose} className="registration-alert-controls-cancel">
          Закрыть
        </Button>
        <NavLink to={Paths.LOGIN_HOME} className={"registration-alert-controls-link"}>
          Зарегистрироваться
        </NavLink>
      </Box>
    </Box>
  );
};
