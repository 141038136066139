import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../store";

export const selectUsersActivities = (state: RootState) => state.admin.usersActivities;

export const selectUsers = createSelector(
  (state: RootState) => state.admin.usersActivities,
  (usersActivities) => {
    const users: Record<string, { name: string; useCount: number }> = {};
    Object.values(usersActivities).forEach((dayActivities) => {
      dayActivities.forEach((activity) => {
        if (!users[activity.userName]) {
          users[activity.userName] = {
            name: activity.userName,
            useCount: 0,
          };
        }
        users[activity.userName].useCount++;
      });
    });

    return Object.values(users)
      .map(({ name, useCount }) => ({
        name,
        useCount,
      }))
      .sort((a, b) => b.useCount - a.useCount);
  },
);
