/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddRecipeRequest } from '../models/AddRecipeRequest';
import type { RecipeDetailedDto } from '../models/RecipeDetailedDto';
import type { RecipeShortenedDto } from '../models/RecipeShortenedDto';
import type { UpdateRecipeRequest } from '../models/UpdateRecipeRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RecipesService {

    /**
     * @param nameFilter 
     * @returns RecipeShortenedDto Success
     * @throws ApiError
     */
    public static getApiRecipes(
nameFilter?: string,
): CancelablePromise<Array<RecipeShortenedDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/recipes',
            query: {
                'nameFilter': nameFilter,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postApiRecipes(
requestBody?: AddRecipeRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/recipes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param recipeId 
     * @returns RecipeDetailedDto Success
     * @throws ApiError
     */
    public static getApiRecipes1(
recipeId: number,
): CancelablePromise<RecipeDetailedDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/recipes/{recipeId}',
            path: {
                'recipeId': recipeId,
            },
        });
    }

    /**
     * @param recipeId 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static putApiRecipes(
recipeId: number,
requestBody?: UpdateRecipeRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/recipes/{recipeId}',
            path: {
                'recipeId': recipeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param recipeId 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiRecipes(
recipeId: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/recipes/{recipeId}',
            path: {
                'recipeId': recipeId,
            },
        });
    }

}
