import { SequentialTaskRunner } from "../core/SequentialTaskRunner";
import { Syncer } from "../core/Syncer";

export class SyncService {
  private static intervalId: NodeJS.Timer | null = null;
  private static sequentialTaskRunner: SequentialTaskRunner = new SequentialTaskRunner();
  private static syncers: Syncer[] = [];
  private static checkSignIn: () => boolean = () => false;
  private static setIsOnline: (isOnline: boolean) => void = () => undefined;

  public static enqueue(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.sequentialTaskRunner.enqueue(async () => {
        const isSignedIn = this.checkSignIn();
        const changesChecks = new Map<Syncer, boolean>();
        for (const syncer of this.syncers) {
          try {
            changesChecks.set(
              syncer,
              isSignedIn && (await syncer.changesTracker.check()),
            );
          } catch (e) {
            this.setIsOnline(false);
            reject(e);
            throw e;
          }
        }
        this.setIsOnline(true);
        for (const syncer of this.syncers) {
          await syncer.sync(isSignedIn, !!changesChecks.get(syncer));
        }
        resolve(true);
      });
    });
  }

  public static run(
    interval: number,
    checkSignIn: () => boolean,
    setIsOnline: (isOnline: boolean) => void,
    syncers: Syncer[],
  ): void {
    if (!this.intervalId) {
      this.syncers = syncers;
      this.checkSignIn = checkSignIn;
      this.setIsOnline = setIsOnline;
      this.intervalId = setInterval(() => this.enqueue(), interval);
    }
  }

  public static stop() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }
}
