import React from "react";

import { CurtainPopup } from "../../CurtainPopup/CurtainPopup";
import { RecipeForm, RecipeFormData } from "../../RecipeForm/RecipeForm";

export interface CreateRecipeDialogProps {
  open: boolean;
  onClose: () => void;
  onFromSubmit: (recipeData: RecipeFormData) => Promise<void>;
}

export const CreateRecipeDialog = (props: CreateRecipeDialogProps) => {
  return (
    <CurtainPopup open={props.open} onClose={props.onClose}>
      <RecipeForm
        id="create-recipe-form"
        formTitle="Новый рецепт"
        onSubmit={props.onFromSubmit}
        onClose={props.onClose}
      />
    </CurtainPopup>
  );
};
