import dayjs from "dayjs";

import { START_DAY_FORMAT, END_DAY_FORMAT } from "./constants";

export const getUniqueId = () => Date.now() + Math.floor(Math.random() * 1000);

export const getDateValue = (dateString: string) => new Date(dateString).valueOf();

export const getLastDaysPeriod = (days = 7) => {
  const startDate = dayjs()
    .subtract(days - 1, "day")
    .format(START_DAY_FORMAT);
  const endDate = dayjs().format(END_DAY_FORMAT);
  return { startDate, endDate };
};
