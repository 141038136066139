import { Menu } from "@mui/material";
import { ReactNode } from "react";

import "./AnchorMenu.scss";

interface AnchorMenuProps {
  anchor: HTMLElement | null;
  onClose: () => void;
  children?: ReactNode;
}

export const AnchorMenu = (props: AnchorMenuProps) => {
  return (
    <Menu
      className="anchor-menu"
      aria-labelledby="list options"
      anchorEl={props.anchor}
      open={!!props.anchor}
      onClose={props.onClose}
    >
      {props.children}
    </Menu>
  );
};
