import { useEffect } from "react";

import { CurtainPopup } from "../../CurtainPopup/CurtainPopup";
import { RecipeDetailed, RecipeDetailedData } from "../../RecipeDetailed/RecipeDetailed";
import { RecipeForm, RecipeFormData } from "../../RecipeForm/RecipeForm";

export interface RecipeDetailedDialogProps {
  open: boolean;
  onClose: () => void;
  recipe: RecipeDetailedData | null;
  isRecipeLoading: boolean;
  isEdit: boolean;
  onEditRecipeToggle: (isEdit: boolean) => void;
  isRecipeUpdating: boolean;
  onRecipeChange: (id: number, recipeData: RecipeFormData) => Promise<void>;
  onAddToListClick: () => void;
}

export const RecipeDetailedDialog = (props: RecipeDetailedDialogProps) => {
  const handleFormSubmit = async (recipeData: RecipeFormData) => {
    if (props.recipe) {
      props.onRecipeChange(props.recipe.id, recipeData).catch(console.log);
    }
  };

  useEffect(() => {
    if (!props.open) {
      props.onEditRecipeToggle(false);
    }
  }, [props.open]);

  return (
    <CurtainPopup open={props.open} onClose={props.onClose}>
      {!props.isEdit && (
        <RecipeDetailed
          recipe={props.recipe}
          isLoading={props.isRecipeLoading || props.isRecipeUpdating}
          onBack={props.onClose}
          onChangeClick={() => props.onEditRecipeToggle(true)}
          onAddToListClick={props.onAddToListClick}
        />
      )}
      <RecipeForm
        id="recipe-detailed-edit-recipe-form"
        formTitle="Редактирование рецепта"
        onSubmit={handleFormSubmit}
        onClose={() => props.onEditRecipeToggle(false)}
        initialData={props.recipe}
        hidden={!props.isEdit || props.isRecipeLoading}
      />
    </CurtainPopup>
  );
};
