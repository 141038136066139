export const Upload = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M9.16658 15.8333H10.8333V12.3542L12.1666 13.6875L13.3333 12.5L9.99992 9.16667L6.66658 12.5L7.85408 13.6667L9.16658 12.3542V15.8333ZM4.99992 18.3333C4.54159 18.3333 4.14922 18.1701 3.82284 17.8438C3.49645 17.5174 3.33325 17.125 3.33325 16.6667V3.33333C3.33325 2.875 3.49645 2.48264 3.82284 2.15625C4.14922 1.82986 4.54159 1.66666 4.99992 1.66666H11.6666L16.6666 6.66666V16.6667C16.6666 17.125 16.5034 17.5174 16.177 17.8438C15.8506 18.1701 15.4583 18.3333 14.9999 18.3333H4.99992ZM10.8333 7.5V3.33333H4.99992V16.6667H14.9999V7.5H10.8333Z"
        fill="#08875D"
      />
    </svg>
  );
};
