import { Box, Button, Checkbox, IconButton, ListItem, Typography } from "@mui/material";
import React, { MouseEvent, useState } from "react";

import "./RecipeCard.scss";
import { CheckboxSquare } from "../../assets/Icons/CheckboxSquare";
import { CheckboxSquareChecked } from "../../assets/Icons/CheckboxSquareChecked";
import { Clock } from "../../assets/Icons/Clock";
import { Delete } from "../../assets/Icons/Delete";
import { EditRecipe } from "../../assets/Icons/EditRecipe";
import { HorizontalKebab } from "../../assets/Icons/HorizontalKebab";
import { OrderedList } from "../../assets/Icons/OrderedList";
import { Persons } from "../../assets/Icons/Persons";
import recipe from "../../assets/img/recipe.svg";
import { formatDuration } from "../../utils/normalizeText";
import { AnchorMenu } from "../AnchorMenu/AnchorMenu";

export type RecipeCardData = {
  id: number;
  name: string;
  persons?: number;
  cookingTime?: string | null;
  photoUrl?: string | null;
};

export interface RecipeCardProps {
  recipeData?: RecipeCardData;
  isSelectable?: boolean;
  isSelected?: boolean;
  onClick: (recipeId: number) => void;
  onDeleteClick: (recipeId: number) => void;
  onEditClick: (recipeId: number) => void;
  onToggleClick: (recipeId: number) => void;
}

export const RecipeCard = (props: RecipeCardProps) => {
  const [anchorRecipeMenu, setAnchorRecipeMenu] = useState<null | HTMLElement>(null);

  const handleClick = () => {
    if (props.recipeData?.id && !anchorRecipeMenu) {
      props.onClick(props.recipeData.id);
    }
  };

  const handleMenuClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorRecipeMenu(e.currentTarget);
  };

  const handleToggleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (props.recipeData?.id) {
      props.onToggleClick(props.recipeData.id);
    }
  };

  const handleMenuItemClick = (
    e: MouseEvent<HTMLButtonElement>,
    callback: (recipeId: number) => void,
  ) => {
    e.stopPropagation();
    setAnchorRecipeMenu(null);
    if (props.recipeData?.id) {
      callback(props.recipeData.id);
    }
  };

  return (
    <Box
      className="recipe-card"
      sx={{
        background: props.recipeData?.photoUrl
          ? `linear-gradient(180deg, rgba(0, 0, 0, 0.60) 37.93%, rgba(0, 0, 0, 0.00) 100%), 
          url(${props.recipeData.photoUrl}) lightgray 50% / cover no-repeat`
          : `url(${recipe}) var(--secondary-neutral-500, #8399A8) center no-repeat`,
      }}
      onClick={handleClick}
    >
      <Box className="recipe-card-heading">
        <Typography className="recipe-card-heading-name">
          {props.recipeData?.name}
        </Typography>
        {props.isSelectable ? (
          <Checkbox
            className="recipe-card-heading-button"
            checked={props.isSelected}
            onClick={handleToggleClick}
            icon={<CheckboxSquare />}
            checkedIcon={<CheckboxSquareChecked />}
          />
        ) : (
          <IconButton className="recipe-card-heading-button" onClick={handleMenuClick}>
            <HorizontalKebab />
          </IconButton>
        )}
      </Box>
      <Box className="recipe-card-info">
        <Box className="recipe-card-info-persons">
          <Persons />
          <Typography>{props.recipeData?.persons}</Typography>
        </Box>
        <Box className="recipe-card-info-time">
          <Clock />
          <Typography>{formatDuration(props.recipeData?.cookingTime)}</Typography>
        </Box>
      </Box>
      <AnchorMenu anchor={anchorRecipeMenu} onClose={() => setAnchorRecipeMenu(null)}>
        <ListItem>
          <Button
            variant="text"
            endIcon={<EditRecipe />}
            onClick={(e) => handleMenuItemClick(e, props.onEditClick)}
          >
            <Typography>Изменить</Typography>
          </Button>
        </ListItem>
        <ListItem>
          <Button
            variant="text"
            endIcon={<OrderedList />}
            onClick={(e) => handleMenuItemClick(e, props.onToggleClick)}
          >
            <Typography>Добавить в список</Typography>
          </Button>
        </ListItem>
        <ListItem>
          <Button
            variant="text"
            color="error"
            endIcon={<Delete color="#E02D3C" />}
            onClick={(e) => handleMenuItemClick(e, props.onDeleteClick)}
          >
            <Typography>Удалить рецепт</Typography>
          </Button>
        </ListItem>
      </AnchorMenu>
    </Box>
  );
};
