import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";

import { ListInternalModel } from "../../../services/internalStorage/models/ListInternalModel";
import { BaseInput } from "../../BaseInput/BaseInput";
import { InputLabel } from "../../InputLabel/InputLabel";
import "./EditListDialog.scss";

export interface EditListDialogProps {
  open: boolean;
  listToEdit: ListInternalModel;
  onClose: () => void;
  onConfirm: (list: ListInternalModel) => void;
}

export const EditListDialog = (props: EditListDialogProps) => {
  const [open, setOpen] = useState(props.open);
  const [isInputOpen, setIsInputOpen] = useState(false);
  const [newListName, setNewListName] = useState(props.listToEdit?.name ?? "");

  useEffect(() => {
    if (props.listToEdit?.name) {
      setNewListName(props.listToEdit.name);
    }
  }, [props.listToEdit?.name]);

  const handleClose = () => {
    props.onClose();
  };

  const handleAdd = () => {
    if (newListName && props.listToEdit) {
      props.onConfirm({ ...props.listToEdit, name: newListName });
      props.onClose();
    }
  };

  useEffect(() => {
    setOpen(props.open);
    if (props.open) {
      setTimeout(() => {
        setIsInputOpen(true);
      }, 100);
    } else {
      setIsInputOpen(false);
    }
  }, [props.open]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewListName(event.target.value.slice(0, 200));
  };

  return (
    <Dialog open={open} onClose={handleClose} className="edit-list-dialog">
      <DialogTitle className="edit-list-dialog-title">Переименование списка</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        className="edit-list-dialog-close"
      >
        <Close />
      </IconButton>
      <DialogContent className="edit-list-dialog-content">
        <InputLabel>Новое название</InputLabel>
        {isInputOpen && (
          <BaseInput
            placeholder="Укажите название"
            value={newListName}
            onChange={handleInputChange}
            onEnter={handleAdd}
            autoFocus={true}
          />
        )}
      </DialogContent>
      <DialogActions className="edit-list-dialog-actions">
        <Button
          className="edit-list-dialog-actions-button"
          onClick={handleAdd}
          variant="contained"
          disabled={!newListName}
        >
          Переименовать
        </Button>
      </DialogActions>
    </Dialog>
  );
};
