export const CheckboxSquareChecked = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M2 5.61111C2 4.65338 2.38046 3.73488 3.05767 3.05767C3.73488 2.38046 4.65338 2 5.61111 2H18.3889C19.3466 2 20.2651 2.38046 20.9423 3.05767C21.6195 3.73488 22 4.65338 22 5.61111V18.3889C22 19.3466 21.6195 20.2651 20.9423 20.9423C20.2651 21.6195 19.3466 22 18.3889 22H5.61111C4.65338 22 3.73488 21.6195 3.05767 20.9423C2.38046 20.2651 2 19.3466 2 18.3889V5.61111Z"
        fill="#F8FAFC"
      />
      <path
        d="M5.61111 2C4.65338 2 3.73488 2.38046 3.05767 3.05767C2.38046 3.73488 2 4.65338 2 5.61111V18.3889C2 19.3466 2.38046 20.2651 3.05767 20.9423C3.73488 21.6195 4.65338 22 5.61111 22H18.3889C19.3466 22 20.2651 21.6195 20.9423 20.9423C21.6195 20.2651 22 19.3466 22 18.3889V5.61111C22 4.65338 21.6195 3.73488 20.9423 3.05767C20.2651 2.38046 19.3466 2 18.3889 2H5.61111ZM17.8667 8.97778L10.3622 16.4744C10.206 16.6305 9.99417 16.7182 9.77333 16.7182C9.5525 16.7182 9.34069 16.6305 9.18444 16.4744L6.13333 13.4222C5.98613 13.2643 5.906 13.0553 5.90981 12.8394C5.91361 12.6235 6.00107 12.4175 6.15375 12.2649C6.30643 12.1122 6.51242 12.0247 6.72831 12.0209C6.9442 12.0171 7.15314 12.0972 7.31111 12.2444L9.77444 14.7067L16.6889 7.79889C16.7664 7.72156 16.8583 7.66024 16.9595 7.61844C17.0607 7.57665 17.1691 7.55519 17.2786 7.55529C17.388 7.55539 17.4964 7.57706 17.5975 7.61904C17.6986 7.66103 17.7904 7.72252 17.8678 7.8C17.9451 7.87748 18.0064 7.96943 18.0482 8.07061C18.09 8.17179 18.1115 8.2802 18.1114 8.38967C18.1113 8.49914 18.0896 8.60752 18.0476 8.70862C18.0056 8.80972 17.9441 8.90156 17.8667 8.97889V8.97778Z"
        fill="#08875D"
      />
    </svg>
  );
};
