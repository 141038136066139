import { Alert, IconButton, Typography } from "@mui/material";
import React, { KeyboardEvent, useEffect, useRef } from "react";
import "./LoginInput.scss";

interface LoginInputProps {
  value: string;
  onChange: (text: string) => void;
  icon?: React.ReactNode;
  onIconClick?: () => void;
  name: string;
  type: string;
  isError?: boolean;
  errorText?: string;
  autofocus?: boolean;
  disabled?: boolean;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  placeholder?: string;
}

export const LoginInput = (props: LoginInputProps) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.autofocus && ref.current) {
      ref.current.focus();
    }
  }, [props.autofocus]);

  return (
    <div className="login-input-wrapper">
      <Typography className="login-input-name">{props.name}</Typography>
      <div className="login-input-container">
        {props.type != "text-area" ? (
          <input
            className="login-input"
            type={props.type}
            onChange={(e) => props.onChange(e.target.value)}
            value={props.value}
            autoFocus={props.autofocus}
            disabled={!!props.disabled}
            onKeyDown={props.onKeyDown}
            ref={ref}
            placeholder={props.placeholder}
          />
        ) : (
          <textarea
            className="login-text-area"
            onChange={(e) => props.onChange(e.target.value)}
            value={props.value}
            disabled={!!props.disabled}
            onKeyDown={props.onKeyDown}
          />
        )}

        <IconButton
          className={"login-icon " + (props.value?.length ? "active" : "")}
          onClick={props.onIconClick}
        >
          {props.icon}
        </IconButton>
      </div>
      {props.isError && (
        <Alert className="login-error" severity="error">
          {props.errorText}
        </Alert>
      )}
    </div>
  );
};
