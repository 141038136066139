import "./RecipesToListBar.scss";
import { Box, Button, Typography } from "@mui/material";

import { Arrow } from "../../assets/Icons/Arrow";

interface RecipesToListBarProps {
  count: number;
  onAddToList: () => void;
}

export const RecipesToListBar = (props: RecipesToListBarProps) => {
  return (
    <Box className="recipes-to-list-bar">
      <Typography className="recipes-to-list-bar-text">Выбрано:</Typography>
      <Box className="recipes-to-list-bar-count">{props.count}</Box>
      <Button className="recipes-to-list-bar-button" onClick={props.onAddToList}>
        Добавить в список <Arrow />
      </Button>
    </Box>
  );
};
