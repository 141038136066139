import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { Chip, Box, Typography, Avatar, List, ListItem } from "@mui/material";

import { ListInternalModel } from "../../services/internalStorage/models/ListInternalModel";
import "./ListsList.scss";

export interface ListsListProps {
  lists: ListInternalModel[];
  selectedListId: number | null;
  onSelectList: (id: number) => void;
  user?: { id?: string } | null;
}

export const ListsList = (props: ListsListProps) => {
  return (
    <List className="lists-list">
      {props.lists?.map((list) => (
        <ListItem
          key={list.localId}
          value={list.localId}
          onClick={() => props.onSelectList(list.localId)}
          className="lists-list-item"
        >
          <Box className="lists-list-item-body">
            <Typography
              className={`lists-list-item-body-text ${props.selectedListId === list?.localId ? "lists-list-item-body-text_active" : ""}`}
            >
              {list.name}
            </Typography>
            {list?.owner?.id && props.user?.id && list.owner.id !== props.user.id && (
              <Chip
                avatar={
                  <Avatar alt="List owner avatar" src={list?.owner?.profileUrl ?? ""} />
                }
                label={list.owner.name}
              />
            )}
          </Box>
          {props.selectedListId === list?.localId && (
            <CheckOutlinedIcon fontSize="small" className="lists-list-item-check" />
          )}
        </ListItem>
      ))}
    </List>
  );
};
