import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import "./CopyItemsDialog.scss";
import { useState } from "react";

import { ListInternalModel } from "../../../services/internalStorage/models/ListInternalModel";
import { getLabel } from "../../../utils/normalizeText";
import { ListsList } from "../../ListsList/ListsList";

export interface CopyItemsDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (listLocalId: number) => void;
  itemsCount: number;
  lists: ListInternalModel[];
  user?: { id?: string } | null;
}

export const CopyItemsDialog = (props: CopyItemsDialogProps) => {
  const [checkedListLocalId, setCheckedListLocalId] = useState<number | null>(null);

  const handleClose = () => {
    props.onClose();
    setCheckedListLocalId(null);
  };

  const handleConfirm = () => {
    if (checkedListLocalId) {
      props.onConfirm(checkedListLocalId);
      handleClose();
    }
  };

  return (
    <Dialog open={props.open} onClose={handleClose} className="copy-items-dialog">
      <DialogTitle className="copy-items-dialog-title">Скопировать товары</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        className="copy-items-dialog-close"
      >
        <Close />
      </IconButton>
      <DialogContent className="copy-items-dialog-content">
        {!props.itemsCount ? (
          <Typography className="copy-items-dialog-content-text">
            Нет товаров для копирования
          </Typography>
        ) : (
          <>
            <Typography className="copy-items-dialog-content-text">
              Выберите список, куда скопировать{" "}
              {getLabel(props.itemsCount, {
                singular: "товар",
                few: "товара",
                many: "товаров",
              })}
            </Typography>
            <ListsList
              lists={props.lists}
              onSelectList={(id) => setCheckedListLocalId(id)}
              selectedListId={checkedListLocalId}
              user={props.user}
            />
          </>
        )}
      </DialogContent>
      <DialogActions className="copy-items-dialog-actions">
        <Button
          onClick={handleConfirm}
          variant="contained"
          className="copy-items-dialog-actions-confirm"
          disabled={!props.itemsCount || !checkedListLocalId}
        >
          Принять
        </Button>
      </DialogActions>
    </Dialog>
  );
};
