export const EditRecipe = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
    >
      <path
        d="M10.9026 5.57761L10.9026 5.57759C11.0139 5.46627 11.1374 5.38607 11.273 5.33675C11.4092 5.28721 11.5453 5.2625 11.6813 5.2625C11.8296 5.2625 11.9718 5.29032 12.1078 5.34594C12.2436 5.40152 12.3673 5.48493 12.4787 5.59634L13.1722 6.28983C13.2711 6.40122 13.3484 6.52492 13.4041 6.66098C13.4597 6.7971 13.4875 6.933 13.4875 7.06875C13.4875 7.20471 13.4628 7.34395 13.4132 7.48652C13.3638 7.62841 13.2836 7.75493 13.1724 7.86616L9.05107 11.9875H6.7625V9.69894L10.9026 5.57761ZM7.8625 10.875V10.8875H7.875H8.5875V10.8928L8.59638 10.8838L10.8651 8.5963L10.8737 8.5877L10.8653 8.5789L10.8651 8.57866L10.5278 8.22265L10.5278 8.22264L10.5273 8.22218L10.1713 7.88491L10.1711 7.88468L10.1623 7.87634L10.1537 7.88487L7.8662 10.1536L7.8625 10.1499V10.1625V10.875ZM0.0125 7.4875V6.0125H5.2375V7.4875H0.0125ZM0.0125 4.4875V3.0125H8.2375V4.4875H0.0125ZM0.0125 1.4875V0.0125H8.2375V1.4875H0.0125Z"
        fill="#08875D"
        stroke="#08875D"
        strokeWidth="0.025"
      />
    </svg>
  );
};
