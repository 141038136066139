import "./RecipeDetailed.scss";
import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

import { Clock } from "../../assets/Icons/Clock";
import { Persons } from "../../assets/Icons/Persons";
import { formatDuration } from "../../utils/normalizeText";
import { CurtainPopupHeading } from "../CurtainPopupHeading/CurtainPopupHeading";
import { EmptyContent } from "../EmptyContent/EmptyContent";
import { InputLabel } from "../InputLabel/InputLabel";
import { RecipeItems } from "../RecipeItems/RecipeItems";

export type RecipeDetailedData = {
  id: number;
  name: string;
  photoUrl?: string | null;
  persons?: number;
  cookingTime?: string | null;
  description?: string | null;
  recipeItems?: Array<{ id: number; name: string; quantity: number }> | null;
};

export interface RecipeDetailedProps {
  recipe?: RecipeDetailedData | null;
  isLoading?: boolean;
  onBack?: () => void;
  onChangeClick?: () => void;
  onAddToListClick?: () => void;
}

export const RecipeDetailed = (props: RecipeDetailedProps) => {
  if (props.isLoading || !props.recipe) {
    return <EmptyContent image={<CircularProgress />} />;
  }

  return (
    <Box className="recipe-detailed">
      <CurtainPopupHeading
        title="Рецепт"
        onCancel={props.onBack}
        onCancelText="Назад"
        onConfirm={props.onChangeClick}
        onConfirmText="Изменить"
      />
      <Box className="recipe-detailed-body">
        <Box className="recipe-detailed-body-image">
          {props.recipe.photoUrl ? (
            <Box
              className="recipe-detailed-body-image-photo"
              component="img"
              src={props.recipe.photoUrl}
            />
          ) : (
            <Box className="recipe-detailed-body-image-fallback" />
          )}
        </Box>
        <Box className="recipe-detailed-body-info">
          <Box className="recipe-detailed-body-info-section">
            <Persons />
            <Typography>{props.recipe.persons}</Typography>
          </Box>
          <Box className="recipe-detailed-body-info-section">
            <Clock />
            <Typography>{formatDuration(props.recipe.cookingTime)}</Typography>
          </Box>
        </Box>
        <Box>
          <InputLabel>Название</InputLabel>
          <Typography>{props.recipe.name}</Typography>
        </Box>
        <Box className="recipe-detailed-body-items">
          <InputLabel>Ингредиенты</InputLabel>
          <RecipeItems
            items={props.recipe.recipeItems ?? []}
            disabled={true}
            onAddToListClick={props.onAddToListClick}
          />
        </Box>
        <Box>
          <InputLabel>Приготовление</InputLabel>
          <Typography>{props.recipe.description}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
